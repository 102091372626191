import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import MuiMenuItem from "@mui/material/MenuItem";

export const Input = styled(TextField)`
	--TextField-brandBorderColor: rgba(0, 0, 0, 0.42);
	--TextField-brandBorderHoverColor: #3f51b5;
	--TextField-brandBorderFocusedColor: #3f51b5;

	& label.Mui-focused {
		color: var(--TextField-brandBorderFocusedColor);
	}

	.MuiFilledInput-root {
		&::before,
		&::after {
			border-bottom: 2px solid var(--TextField-brandBorderColor);
		}

		&:hover:not(.Mui-disabled, .Mui-error):before {
			border-bottom: 2px solid var(--TextField-brandBorderHoverColor);
		}

		&.Mui-focused:after {
			border-bottom: 2px solid var(--TextField-brandBorderFocusedColor);
		}
	}
`;

Input.defaultProps = {
	variant: "outlined",
	fullWidth: true,
};

export const MenuItem = styled(MuiMenuItem)`
	font-weight: 600;
	line-height: 150%;
	padding: 8px 12px;
	border-bottom: 1px solid ${({theme}) => theme.palette.leaderboard.border};
	min-height: 40px;

	&:hover {
		color: #000f9f;
	}
`;
