import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import type {AxiosError} from "axios";
import {isEqual} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeagueStatus, LeagueType, ModalType, RankStatus, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {type IUserStore} from "data/stores/user/user.store";
import {getRankStatus} from "data/utils/getRankStatus";

export interface ILeagueData extends ILeague {
	redirect: string;
	rankStatus: RankStatus;
}

export interface ILeaguesData {
	leagues: ILeagueData[];
	nextPage: boolean;
	page: number;
}

export interface IMyLeaguesController extends ViewController {
	readonly i18n: ILocalizationStore;

	get leagues(): ILeaguesData;
	get isLoading(): boolean;
	get clubLeague(): ILeague | undefined;
	get playoffLeague(): ILeague | undefined;

	loadMoreMyLeagues: () => void;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable _requestState = RequestState.PENDING;

	private getLeagueRedirectPath = (league: ILeague) => {
		if (league.status !== LeagueStatus.SCHEDULED) {
			return "ladder";
		}

		const isManager = league.leagueManager?.userId === this._userStore.user?.id;

		return isManager ? "settings" : "about";
	};

	get leagues() {
		return {
			...this._leaguesStore.myLeagues,
			leagues: this._leaguesStore.myLeagues.leagues
				.filter(
					(league) => ![LeagueType.FAN, LeagueType.OVERALL_PLAYOFF].includes(league.class)
				)
				.map((league) => ({
					...league,
					redirect: this.getLeagueRedirectPath(league),
					rankStatus: getRankStatus({prevRank: league.prevRank, rank: league.rank}),
				})),
		};
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get clubLeague() {
		return this._leaguesStore.myLeagues.leagues.find(
			(league) => league.class === LeagueType.FAN
		);
	}

	get playoffLeague() {
		return this._leaguesStore.myLeagues.leagues.find(
			(league) => league.class === LeagueType.OVERALL_PLAYOFF
		);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	loadMoreMyLeagues = () => {
		this._requestState = RequestState.PENDING;
		this._leaguesStore.fetchMoreMyLeagues().then(this.onSuccess).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action init() {
		this._leaguesStore.fetchMyLeagues().then(this.onSuccess).catch(this.onError);
	}

	dispose() {
		this._leaguesStore.clearStore();
	}
}
