import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {MouseEvent} from "react";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IMenuStore} from "data/stores/menu/menu.store";

export interface IMenuItemController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get className(): string;

	toggleMenu: () => void;
	toggleList: (e: MouseEvent) => void;
}

@injectable()
export class MenuItemController implements IMenuItemController {
	@observable private _isOpen = true;

	get isOpen() {
		return this._isOpen;
	}

	get className() {
		return this._isOpen ? "open" : "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.MenuStore) public _menuStore: IMenuStore
	) {
		makeAutoObservable(this);
	}

	toggleList = (e: MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();

		this._isOpen = !this._isOpen;
	};

	toggleMenu = () => {
		this._menuStore.toggleMenu();
	};
}
