import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {isNumber} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {IContest, type IContestsStore} from "data/stores/contests/contests.store";
import {ContestStatus, ModalType} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IGameBarStore} from "data/stores/game_bar/game_bar.store";

export enum StatusIconType {
	InComplete,
	Complete,
}

export interface IActionsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get predictionsLength(): number;
	get openQuestionsLength(): number;
	get statusIconType(): StatusIconType;
	get isSaveDisabled(): boolean;
	get isShowStats(): boolean;
	get contest(): IContest | undefined;
	get points(): number | string;
	get rank(): number | string;
	get overallPoints(): number | string;

	saveHandler: () => void;
}

@injectable()
export class ActionsController implements IActionsController {
	get predictionsLength() {
		return this._predictionsStore.openQuestionPredictions.length;
	}

	get openQuestionsLength() {
		return this._contestsStore.openQuestions.length;
	}

	get isSaveDisabled() {
		return (
			this.predictionsLength !== this.openQuestionsLength ||
			!this.openQuestionsLength ||
			!this._predictionsStore.hasChanges
		);
	}

	get statusIconType() {
		return !this.isSaveDisabled ? StatusIconType.Complete : StatusIconType.InComplete;
	}

	get contest() {
		return this._contestsStore.selectedContest;
	}

	get isShowStats() {
		if (this._predictionsStore.isSummaryView) {
			return true;
		}

		return this.contest?.status === ContestStatus.Complete;
	}

	get gameBar() {
		return this._gameBarStore.gameBar;
	}

	get points() {
		return this.getValue(this.gameBar.contestPoints);
	}

	get rank() {
		return this.getValue(this.gameBar.contestRank);
	}

	get overallPoints() {
		return this.getValue(this.gameBar.overallPoints);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	private showNextContestModal = (availableContestId: number) => {
		this._modalsStore.showModal(ModalType.NEXT_CONTEST, {
			contestId: this.contest?.id,
			availableContestId,
		});
	};

	private showLeaguesModal = () => {
		this._modalsStore.showModal(ModalType.LEAGUES, {
			contestId: this.contest?.id,
		});
	};

	private showBoosterModal = () => {
		this._modalsStore.showModal(ModalType.BOOSTER, {
			contestId: this.contest?.id,
		});
	};

	saveHandler = async () => {
		try {
			if (!this._predictionsStore.hasBoostedPrediction) {
				this.showBoosterModal();
				return;
			}

			const availableContestId = await this._predictionsStore.savePredictions();

			if (isNumber(availableContestId)) {
				this.showNextContestModal(availableContestId);
			} else {
				this.showLeaguesModal();
			}
		} catch (e) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});
		}
	};

	private getValue = (value: null | number) => value ?? "-";
}
