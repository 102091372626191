import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore, IQuestion} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface ISummaryViewController extends ViewController {
	readonly i18n: ILocalizationStore;

	get questions(): IQuestion[];
}

@injectable()
export class SummaryViewController implements ISummaryViewController {
	get contest() {
		return this._contestsStore.selectedContest;
	}

	get questions() {
		return this.contest?.questions || [];
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}
}
