import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface ISliderStore {
	get isAnimationPlaying(): boolean;
	get index(): number;

	setIndex: (index: number) => void;
	setIsAnimationPlaying: (isDisabled: boolean) => void;
}

@injectable()
export class SliderStore implements ISliderStore {
	@observable _index: number = 0;
	@observable private _isAnimationPlaying = false;

	get isAnimationPlaying() {
		return this._isAnimationPlaying;
	}

	get index() {
		return this._index;
	}

	constructor() {
		makeAutoObservable(this);
	}

	setIndex = (index: number) => {
		this._index = index;
	};

	setIsAnimationPlaying = (isPlay: boolean) => {
		this._isAnimationPlaying = isPlay;
	};
}
