import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {TUTORIAL_STORAGE_KEY} from "data/constants";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";

export interface IGameBarController extends ViewController {
	i18n: ILocalizationStore;

	get isShowTutorialPrompt(): boolean;

	openTutorial: () => void;
	closePrompt: () => void;
}

@injectable()
export class GameBarController implements IGameBarController {
	@observable private _isShowPrompt = !JSON.parse(
		localStorage.getItem(TUTORIAL_STORAGE_KEY) || "false"
	);

	get isShowTutorialPrompt() {
		if (this._predictionsStore.hasPredictions) {
			return false;
		}

		return this._isShowPrompt;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	openTutorial = () => {
		this._tutorialStore.setIsOpen(true);
		this._isShowPrompt = false;
	};

	closePrompt = () => {
		this._isShowPrompt = false;
		localStorage.setItem(TUTORIAL_STORAGE_KEY, "true");
	};
}
