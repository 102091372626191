import {isNumber} from "lodash";
import {RankStatus} from "data/enums";

interface IGetRankStatus {
	rank: number | null;
	prevRank: number | null;
}

export const getRankStatus = ({prevRank, rank}: IGetRankStatus) => {
	// if user don't have rank, we show SAME status
	if (!isNumber(rank)) {
		return RankStatus.SAME;
	}
	// if user don't have lastRank, but have rank, we show UP status
	if (!isNumber(prevRank)) {
		return RankStatus.UP;
	}

	if (rank === prevRank) {
		return RankStatus.SAME;
	}

	return prevRank > rank ? RankStatus.UP : RankStatus.DOWN;
};
