import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {findIndex} from "lodash";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore, IQuestion} from "data/stores/contests/contests.store";
import type {IPrediction, IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {ISliderStore} from "data/stores/slider/slider.store";
import {QuestionStatus} from "data/enums";

interface IProps {
	id: number;
}

interface IQuestionData extends IQuestion {
	/**
	 * Question number
	 */
	number: string;
}

export interface IQuestionController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get question(): IQuestionData | undefined;
	get prediction(): IPrediction | undefined;
	get hasPrediction(): boolean;
	get isBoosterDisabled(): boolean;
	get isBoosted(): boolean;
	get className(): string;
	get isAnimationPlaying(): boolean;
	get isShowBoosterButton(): boolean;

	setPredictionBooster: () => void;
	stopAnimation: () => void;
}

@injectable()
export class QuestionController implements IQuestionController {
	@observable private _id: number = 0;
	@observable private _timerId?: ReturnType<typeof setTimeout>;

	get question() {
		const question = this._contestsStore.getQuestionById(this._id);

		if (!question) {
			return;
		}

		const index = findIndex(this._contestsStore.selectedContest?.questions, {id: question.id});
		const number = index === -1 ? "" : String(index + 1);

		return {
			...question,
			number,
		};
	}

	get prediction() {
		return this._predictionsStore.getPredictionByQuestionId(this._id);
	}

	get hasPrediction() {
		return Boolean(this.prediction?.optionId);
	}

	get isDisabled() {
		return this.question?.status !== QuestionStatus.Open;
	}

	get isBoosterDisabled() {
		return this._predictionsStore.isBoosterDisabled || this.isBoosted || this.isDisabled;
	}

	get isBoosted() {
		return Boolean(this.prediction?.isBoosted);
	}

	get className() {
		return this.isBoosted ? "boosted" : "";
	}

	get isAnimationPlaying() {
		return this._sliderStore.isAnimationPlaying;
	}

	get isShowBoosterButton() {
		return this.question?.status === QuestionStatus.Open || this.isBoosted;
	}

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SliderStore) private _sliderStore: ISliderStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._id = param.id;
	}

	setPredictionBooster = () => {
		const question = this.question;

		if (!question) {
			return;
		}

		this._sliderStore.setIsAnimationPlaying(true);

		this._timerId = setTimeout(() => {
			this._predictionsStore.setPredictionAnswer(question.id, null, true);
		}, 1000);
	};

	stopAnimation = () => {
		this._sliderStore.setIsAnimationPlaying(false);
	};

	dispose() {
		clearTimeout(this._timerId);
	}
}
