import MuiSwitch from "@mui/material/Switch";
import styled from "@emotion/styled";

export const Switch = styled(MuiSwitch)`
	& .MuiSwitch-switchBase.Mui-checked {
		color: #000f9f;

		&:hover {
			background-color: alpha(#9ea4db, theme.palette.action.hoverOpacity);
		}
	}

	& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
		background-color: #9ea4db;
	}
`;
