import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IBannerStore} from "data/stores/banner/banner.store";
import {QuestionStatus} from "data/enums";
import {IQuestion} from "data/stores/contests/contests.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";

export interface IBannerController extends ViewController {
	readonly i18n: ILocalizationStore;

	get type(): QuestionStatus | undefined;
	get question(): IQuestion | undefined;
}

@injectable()
export class BannerController implements IBannerController {
	get question() {
		return this._bannerStore.question;
	}

	get type() {
		if (this._predictionsStore.isSummaryView) {
			return;
		}

		return this.question?.status;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.BannerStore) private _bannerStore: IBannerStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}
}
