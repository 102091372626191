import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	IAuthApiProvider,
	ISsoLoginPayload,
	ISsoRegisterPayload,
} from "data/providers/api/auth.api.provider";
import type {IUpdateUserPayload, IUserApiProvider} from "data/providers/api/user.api.provider";
import type {IPasswordApiProvider} from "data/providers/api/password.api.provider";

export interface IUser {
	id: number;
	email: string;
	displayName: string;
	isNotificationsEnabled: boolean;
	createdAt: string;
	firstName: string | null;
	lastName: string | null;
	country: string | null;
	favouriteTeam: string | null;
	gender: string | null;
}

export interface IUserStore {
	get user(): IUser | undefined;
	get isAuthorized(): boolean;
	get wasLoggedOut(): boolean;

	update(payload: IUpdateUserPayload): Promise<void>;
	register(payload: ISsoRegisterPayload): Promise<void>;
	requestUser(): Promise<void>;
	login(payload: ISsoLoginPayload): Promise<void>;
	logout(): Promise<void>;
	deactivate(): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	@observable private _user?: IUser = undefined;
	@observable private _wasLoggedOut = false;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider
	) {
		makeAutoObservable(this);
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get user() {
		return this._user;
	}

	@action
	async requestUser() {
		const response = await this._userApi.user();
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async login(payload: ISsoLoginPayload) {
		const response = await this._authApi.ssoLogin(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async register(payload: ISsoRegisterPayload) {
		const response = await this._authApi.ssoRegister(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async update(payload: IUpdateUserPayload) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate() {
		await this._userApi.deactivateAccount();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}
}
