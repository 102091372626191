import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {
	ILadder,
	ILeagueRankingsPayload,
	IRankingApiProvider,
} from "data/providers/api/rankings.api.provider";
import {Bindings} from "data/constants/bindings";

export interface ILeagueRankingsStore {
	get contestId(): number;
	get list(): ILadder[];
	get user(): ILadder | null;
	get nextPage(): boolean;

	fetchLeagueRankings: (params: ILeagueRankingsPayload) => Promise<void>;
	loadMoreLeagueRankings: (params: ILeagueRankingsPayload) => Promise<void>;
	setContestId: (id: number) => void;
	clearStore: () => void;
}

@injectable()
export class LeagueRankingsStore implements ILeagueRankingsStore {
	@observable private _contestId: number = 0;
	@observable private _list: ILadder[] = [];
	@observable private _user: ILadder | null = null;
	@observable private _nextPage: boolean = false;
	private _page = 0;

	get contestId() {
		return this._contestId;
	}

	get list() {
		return this._list;
	}

	get user() {
		return this._user;
	}

	get nextPage() {
		return this._nextPage;
	}

	constructor(@inject(Bindings.RankingApiProvider) private _rankingsApi: IRankingApiProvider) {
		makeAutoObservable(this);
	}

	setContestId = (id: number) => {
		this._contestId = id;
	};

	fetchLeagueRankings = async (params: ILeagueRankingsPayload) => {
		this.clearStore();
		const response = await this._rankingsApi.league(params);

		runInAction(() => {
			this._list = response.data.success.ladder;
			this._user = response.data.success.user;
			this._nextPage = response.data.success.nextPage;
			this._page = 1;
		});
	};

	loadMoreLeagueRankings = async (params: ILeagueRankingsPayload) => {
		const page = this._page + 1;

		const response = await this._rankingsApi.league({
			...params,
			page,
		});

		runInAction(() => {
			this._list = this._list.concat(response.data.success.ladder);
			this._user = response.data.success.user;
			this._nextPage = response.data.success.nextPage;
			this._page = page;
		});
	};

	clearStore = () => {
		this._list = [];
		this._user = null;
		this._nextPage = false;
		this._page = 0;
	};
}
