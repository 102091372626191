import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {last} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore, IModalPayload} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestsStore} from "data/stores/contests/contests.store";

export interface IModalNextContestController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get contestName(): string | undefined;
	get modalContent(): IModalPayload | null;
	get nextContestName(): string | undefined;
	get lockDate(): string;

	close: () => void;
	submit: () => void;
}

@injectable()
export class ModalNextContestController implements IModalNextContestController {
	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.NEXT_CONTEST;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get contest() {
		return this._contestsStore.getContestById(this.modalContent?.contestId);
	}

	get contestName() {
		return this.contest?.name;
	}

	get nextContestName() {
		return this._contestsStore.getContestById(this.modalContent?.availableContestId)?.name;
	}

	get lockDate() {
		return last(this.contest?.questions)?.lockDate || "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	submit = () => {
		const availableContestId = this.modalContent?.availableContestId;

		if (availableContestId) {
			this._contestsStore.setSelectedContestId(availableContestId);
		}

		this._modalsStore.hideModal();
	};
}
