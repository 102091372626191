import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {findIndex, get} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore, IQuestion} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPrediction, IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {ISliderStore} from "data/stores/slider/slider.store";
import {QuestionStatus} from "data/enums";

interface IProps {
	id: number;
}

interface IQuestionData extends IQuestion {
	/**
	 * Question number
	 */
	number: string;
}

export interface ISummaryViewCardController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get question(): IQuestionData | undefined;
	get prediction(): IPrediction | undefined;
	get isOpen(): boolean;
	get isLock(): boolean;
	get isComplete(): boolean;
	get answer(): string;
	get correctAnswers(): string[];
	get isCorrect(): boolean;
	get points(): number;
	get className(): string;

	getAnswerCN: (...params: string[]) => string;
	goToQuestion: () => void;
}

@injectable()
export class SummaryViewCardController implements ISummaryViewCardController {
	@observable private _id: number = 0;

	private get questionIndex() {
		return findIndex(this._contestsStore.selectedContest?.questions, {id: this._id});
	}

	get question() {
		const question = this._contestsStore.getQuestionById(this._id);

		if (!question) {
			return;
		}

		const number = this.questionIndex === -1 ? "" : String(this.questionIndex + 1);

		return {
			...question,
			number,
		};
	}

	get prediction() {
		return this._predictionsStore.getPredictionByQuestionId(this._id);
	}

	get isOpen() {
		return this.question?.status === QuestionStatus.Open;
	}

	get isLock() {
		return this.question?.status === QuestionStatus.Locked;
	}

	get isComplete() {
		return this.question?.status === QuestionStatus.Complete;
	}

	get option() {
		return this._contestsStore
			.getQuestionById(this.prediction?.questionId)
			?.options.find(({id}) => id === this.prediction?.optionId);
	}

	get answer() {
		return this.option?.value || "";
	}

	get correctAnswers() {
		return get(this.question, "options", [])
			.filter(({isCorrect}) => isCorrect)
			.map(({value}) => value || "");
	}

	get isCorrect() {
		return this.prediction?.isCorrect || false;
	}

	get points() {
		return this.prediction?.points || 0;
	}

	get className() {
		let cn = "";

		if (this.question?.status) {
			cn += this.question.status;
		}

		if (this.isComplete && this.prediction) {
			if (this.isCorrect) {
				cn += " correct";
			} else {
				cn += " wrong";
			}
		}

		if (this.prediction?.isBoosted) {
			cn += " boosted";
		}

		return cn;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SliderStore) private _sliderStore: ISliderStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._id = param.id;
	}

	goToQuestion = () => {
		if (!this.isOpen) {
			return;
		}

		this._predictionsStore.setIsSummaryView(false);
		this._sliderStore.setIndex(this.questionIndex);
	};

	getAnswerCN = (...params: string[]) => {
		if (this.prediction?.isBoosted) {
			params.push("boosted");
		}

		return params.join(" ");
	};
}
