import React from "react";
import {action, makeAutoObservable, observable} from "mobx";
import {isEqual} from "lodash";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {IApiResponse} from "data/services/http";
import {RequestState} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IMyLeaguesSearchController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get searchValue(): string;

	onSearchLeagueForJoinChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class MyLeaguesSearchController implements IMyLeaguesSearchController {
	@observable searchTimer: ReturnType<typeof setTimeout> | undefined;
	@observable private _searchValue: string = "";
	@observable _requestState = RequestState.IDLE;
	@observable private _navigate?: ReturnType<typeof useNavigate>;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get searchValue() {
		return this._searchValue;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._navigate = param.navigate;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
	};

	@action public onSearchLeagueForJoinChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}
		this._searchValue = value;

		clearTimeout(this.searchTimer);

		this.searchTimer = setTimeout(() => {
			this._leaguesStore
				.fetchLeagueByCode({
					code: this._searchValue,
				})
				.then((league) => {
					if (!league.code) {
						return;
					}

					this._navigate?.(`/leagues/join/${league.code}`);
				})
				.catch(this.onError);
		}, 500);
	};
}
