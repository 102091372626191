import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface ISnackbarStore {
	get isOpen(): boolean;
	get message(): string;

	handleClose: () => void;
	handleOpen: (message: string) => void;
}

@injectable()
export class SnackbarStore implements ISnackbarStore {
	@observable private _isOpen = false;
	@observable private _message = "";

	get isOpen() {
		return this._isOpen;
	}

	get message() {
		return this._message;
	}

	constructor() {
		makeAutoObservable(this);
	}

	handleClose = () => {
		this._isOpen = false;
	};

	handleOpen = (message: string) => {
		this._message = message;
		this._isOpen = true;
	};
}
