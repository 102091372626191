import styled from "@emotion/styled";
import React, {AllHTMLAttributes} from "react";
import CheckIcon from "@mui/icons-material/Check";
import MuiCheckbox from "@mui/material/Checkbox";

const BaseCheckboxContainer = styled.label`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	min-width: 25px;
	width: 25px;
	height: 25px;
	box-sizing: border-box;
	overflow: hidden;

	svg {
		color: transparent;
		font-size: 20px;
		z-index: 1;
	}

	span {
		border-radius: 4px;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 1px solid #d9d9d9;
		background: transparent;
		cursor: pointer;
	}

	input {
		position: absolute;
		margin: 0;
		padding: 0;
		opacity: 0;
		cursor: pointer;

		&:checked + svg {
			color: #fff;
		}

		&:checked + svg + span {
			background: var(--primaryColor);
		}

		&:focus + svg + span {
			display: block;
			border-color: var(--primaryColor);
		}

		&:disabled {
			cursor: default;

			& + svg {
				opacity: 0.5;

				& + span {
					cursor: default;
				}
			}
		}
	}
`;

export const BaseCheckbox: React.FC<AllHTMLAttributes<HTMLInputElement>> = ({
	id,
	className,
	...rest
}) => (
	<BaseCheckboxContainer className={className} htmlFor={id}>
		<input id={id} {...rest} />
		<CheckIcon />
		<span />
	</BaseCheckboxContainer>
);

export const Checkbox = styled(BaseCheckbox)``;

Checkbox.defaultProps = {
	type: "checkbox",
};

export const SMuiCheckbox = styled(MuiCheckbox)`
	color: #6d6d6d;

	&.Mui-checked {
		color: #000f9f;
	}
`;
