import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {BrowserTracing, init, Integrations, Replay} from "@sentry/react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {StyledEngineProvider} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {Session} from "views/components/session/session.component";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {ModalRegister} from "views/components/modals/modal_register/modal_register.component";
import {Snackbar} from "views/components/snackbar/snackbar.component";
import {ModalLeagueInvite} from "views/components/modals/modal_league_invite/modal_league_invite.component";
import {ModalNextContest} from "views/components/modals/modal_next_contest/modal_next_contest.component";
import {ModalLeagues} from "views/components/modals/modal_leagues/modal_leagues.component";
import {ModalBooster} from "views/components/modals/modal_booster/modal_booster.component";
import {theme} from "assets/theming/theme";

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}), // TODO update sentry version and integration
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["*.f2p.media.geniussports.com", "https://dailypicks.iihf.com"],
		ignoreErrors: [
			"Abort due to cancellation of share",
			"AbortError: Share canceled",
			/Network Error/i,
			/Fetch Error/i,
			"Can't find variable: _AutofillCallbackHandler",
		],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

createRoot(root).render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<InjectionProvider container={DIContainer}>
					<Bootstrap>
						<BrowserRouter basename={BASE_URL}>
							<Suspense fallback={<PagePreloader />}>
								{SecretGateController.IS_SECRET_PASSED ? (
									<Session>
										<RootRoutes />
									</Session>
								) : (
									<SecretGateRoutes />
								)}
							</Suspense>

							<ModalLeagues />
							<ModalError />
							<ModalConfirm />
							<ModalRegister />
							<ModalLeagueInvite />
							<ModalNextContest />
							<Snackbar />
							<ModalBooster />
						</BrowserRouter>
					</Bootstrap>
				</InjectionProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
