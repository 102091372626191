/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					height: 48,
					fontWeight: "700",
					fontSize: "14px",
					borderRadius: "1px",
					":hover": {
						":disabled": {
							boxShadow:
								"0px 0.1px 0.3px rgba(28, 28, 30, 0.1), 0px 1px 2px rgba(28, 28, 30, 0.2)",
						},
					},
					":disabled": {
						boxShadow:
							"0px 0.1px 0.3px rgba(28, 28, 30, 0.1), 0px 1px 2px rgba(28, 28, 30, 0.2)",
						background: "grey",
						color: "rgba(17, 17, 17, 0.4)",
						cursor: "default",
					},
				},
				containedPrimary: {
					color: "#ffffff",
					background: "#000F9F",
					border: "1px solid #000F9F",
					borderRadius: 10,
					boxShadow: "none",
					":hover": {
						color: "#ffffff",
						boxShadow: "none",
						background: "#333FB2",
						borderColor: "#333FB2",
					},
					":disabled": {
						boxShadow: "none",
						border: "none",
						color: "#6D6D6D",
						background: "#F6F6F6",
					},
				},
				outlinedPrimary: {
					borderColor: "blue",
					color: "blue",
					":hover": {
						borderColor: "blue",
					},
					":disabled": {
						border: "none",
					},
				},
				containedSecondary: {
					color: "#000",
					background: "#fff",
					border: "1px solid #F9423A",
					borderRadius: 10,
					boxShadow: "none",
					":hover": {
						boxShadow: "none",
						background: "#fff",
						borderColor: "#F9443A",
					},
					":disabled": {
						boxShadow: "none",
						border: "none",
						color: "#6D6D6D",
						background: "#F6F6F6",
					},
				},
				containedError: {
					color: "#E40000",
					background: "#fff",
					border: "1px solid #E40000",
					borderRadius: 10,
					boxShadow: "none",
					":hover": {
						boxShadow: "none",
						background: "#fff",
						borderColor: "#F9443A",
					},
					":disabled": {
						boxShadow: "none",
						border: "none",
						color: "#6D6D6D",
						background: "#F6F6F6",
					},
				},
			},
			variants: [
				{
					props: {className: "byClassName"},
					style: {
						background: "yellow",
						color: "#000",
						":hover": {
							background: "blue",
						},
					},
				},
			],
		},
	},
};
