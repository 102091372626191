import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ChangeEvent} from "react";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContest, IContestsStore} from "data/stores/contests/contests.store";
import {Bindings} from "data/constants/bindings";

export interface IRoundSelectorController extends ViewController {
	readonly i18n: ILocalizationStore;

	get selectedContestId(): number;
	get isLeftDisabled(): boolean;
	get isRightDisabled(): boolean;
	get contests(): IContest[];

	handleRoundChangeClick: (direction: "left" | "right") => void;
	contestSelectHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class RoundSelectorController implements IRoundSelectorController {
	get isLeftDisabled() {
		const contestIndex = this._contestsStore.list.findIndex(
			({id}) => id === this.selectedContestId
		);

		return contestIndex <= 0;
	}

	get isRightDisabled() {
		const contestIndex = this._contestsStore.list.findIndex(
			({id}) => id === this.selectedContestId
		);
		const contestsLength = this._contestsStore.list.length - 1;

		return contestIndex >= contestsLength;
	}

	get selectedContestId() {
		return this._contestsStore.selectedContest?.id || 1;
	}

	get contests() {
		return this._contestsStore.list;
	}

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	handleRoundChangeClick = (direction: "left" | "right") => {
		const contestIndex = this._contestsStore.list.findIndex(
			({id}) => id === this.selectedContestId
		);
		const newContestIndex = direction === "left" ? contestIndex - 1 : contestIndex + 1;
		const newContestId = this._contestsStore.list[newContestIndex]?.id;

		if (!newContestId) {
			return;
		}

		this._contestsStore.setSelectedContestId(newContestId);
	};

	contestSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const roundId = Number(e.target.value);
		this._contestsStore.setSelectedContestId(roundId);
	};
}
