export interface IMenuItem {
	key: string;
	name: string;
	path: string;
	subMenu?: IMenuItem[];
	isMobileOnly?: boolean;
}

export const AUTH_MENU: IMenuItem[] = [
	{
		key: "page.menu.main",
		name: "My Pick",
		path: "/predictor",
	},
	{
		key: "page.menu.leagues",
		name: "Leagues",
		path: "/leagues",
		subMenu: [
			{
				key: "page.menu.leagues_create",
				name: "Create League",
				path: "/leagues/create",
			},
			{
				key: "page.menu.leagues_join",
				name: "Join League",
				path: "/leagues/join",
			},
		],
	},
	{
		key: "page.menu.ranking",
		name: "Ranking",
		path: "/leaderboard",
	},
	{
		key: "page.menu.account",
		name: "My Account",
		path: "/my-account",
		isMobileOnly: true,
	},
	{
		key: "page.menu.help",
		name: "Help",
		path: "/help",
		subMenu: [
			{
				key: "page.menu.rules",
				name: "Rules",
				path: "/help/rules",
			},
			{
				key: "page.menu.faq",
				name: "Faq`s",
				path: "/help/faqs",
			},
			{
				key: "page.menu.terms_conditions",
				name: "Terms & Conditions",
				path: "/help/t&cs",
			},
			{
				key: "page.menu.contact_us",
				name: "Contact Us",
				path: "/help/contact-us",
			},
		],
	},
];

export const GUEST_MENU: IMenuItem[] = [
	{
		key: "page.menu.main",
		name: "Landing",
		path: "/",
	},
	{
		key: "page.menu.help",
		name: "Help",
		path: "/help",
		subMenu: [
			{
				key: "page.menu.rules",
				name: "Rules",
				path: "/help/rules",
			},
			{
				key: "page.menu.faq",
				name: "Faq`s",
				path: "/help/faqs",
			},
			{
				key: "page.menu.terms_conditions",
				name: "Terms & Conditions",
				path: "/help/t&cs",
			},
			{
				key: "page.menu.contact_us",
				name: "Contact Us",
				path: "/help/contact-us",
			},
		],
	},
];
