import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ChangeEvent} from "react";
import {first, get} from "lodash";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore, IQuestion} from "data/stores/contests/contests.store";
import type {IPrediction, IPredictionsStore} from "data/stores/predictions/predictions.store";
import {QuestionStatus} from "data/enums";

interface IProps {
	id: number;
}

export interface IDropDownController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get question(): IQuestion | undefined;
	get prediction(): IPrediction | undefined;
	get selectCN(): string;
	get hasPrediction(): boolean;
	get isDisabled(): boolean;
	get image(): string | undefined;
	get answer(): string | undefined;
	get isLock(): boolean;
	get isComplete(): boolean;
	get isCorrect(): boolean;
	get points(): number;

	setPredictionAnswer: (e: ChangeEvent<HTMLInputElement>) => void;
	setIsOpen: (isOpen: boolean) => void;
}

@injectable()
export class DropDownController implements IDropDownController {
	@observable private _id: number = 0;
	@observable private _isOpenSelect: boolean = false;

	get question() {
		return this._contestsStore.getQuestionById(this._id);
	}

	get prediction() {
		return this._predictionsStore.getPredictionByQuestionId(this._id);
	}

	get hasPrediction() {
		return Boolean(this.prediction?.optionId);
	}

	get isDisabled() {
		return this.question?.status !== QuestionStatus.Open;
	}

	get selectCN() {
		let className = "";

		if (this.hasPrediction) {
			className += "selected";
		}

		if (this._isOpenSelect) {
			className += " select-open";
		}

		return className;
	}

	get image() {
		const optionImage = first(
			this.question?.options.find(({id}) => this.prediction?.optionId === id)?.images
		)?.pathname;

		if (optionImage) {
			return optionImage;
		}

		return this.question?.image?.pathname;
	}

	get options() {
		return get(this.question, "options", []);
	}

	get answer() {
		return this.options.find(({id}) => id === this.prediction?.optionId)?.value;
	}

	get isLock() {
		return this.question?.status === QuestionStatus.Locked;
	}

	get isComplete() {
		return this.question?.status === QuestionStatus.Complete;
	}

	get isCorrect() {
		return this.prediction?.isCorrect || false;
	}

	get points() {
		return this.prediction?.points || 0;
	}

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._id = param.id;
	}

	setPredictionAnswer = (e: ChangeEvent<HTMLInputElement>) => {
		if (!this.question) {
			return;
		}

		this._predictionsStore.setPredictionAnswer(this.question.id, Number(e.target.value));
	};

	setIsOpen = (isOpen: boolean) => {
		this._isOpenSelect = isOpen;
	};
}
