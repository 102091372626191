import React from "react";
import {Button, FormControl, MenuItem} from "@mui/material";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {ErrorText, Input, SMuiCheckbox, Switch} from "views/components/form";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {IModalRegisterController} from "./modal_register.controller";
import {ReactComponent as Icon} from "assets/img/modalLogo.svg";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 769px) {
		max-width: 360px;
	}
`;

const IconWrapper = styled.div`
	padding: 45px 0;
	margin-bottom: 12px;

	@media (max-height: 750px) {
		padding: 12px 0;
	}
`;

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 8px;
`;

const SModalDesc = styled(ModalDesc)`
	margin-bottom: 12px;
`;

const Label = styled(FormControlLabel)`
	.MuiFormControlLabel-label {
		text-align: left;
		color: ${({theme}) => theme.palette.modal.color};
		font-family: var(--fontFamilyBase);
		font-size: 14px;
		font-weight: 400;
		line-height: 140%;
	}

	.MuiFormControlLabel-asterisk {
		display: none;
	}
`;

export const ModalRegister: React.FC = observer(() => {
	const {
		isOpen,
		i18n,
		isFormDisabled,
		error,
		favoriteTeams,
		handleFormSubmit,
		handleFormOnChange,
		isSaveButtonDisabled,
	} = useViewController<IModalRegisterController>(Bindings.ModalRegisterController);

	return (
		<ModalBase isOpen={isOpen}>
			<Container>
				<IconWrapper>
					<Icon />
				</IconWrapper>

				<SModalTitle>{i18n.t("mini_reg.modal.title", "Register")}</SModalTitle>

				<SModalDesc>{i18n.t("mini_reg.modal.body", "Register")}</SModalDesc>

				<form onSubmit={handleFormSubmit} onChange={handleFormOnChange} noValidate>
					<FormControl fullWidth sx={{mb: 2}}>
						<Input
							size="small"
							variant="filled"
							disabled={isFormDisabled}
							label={i18n.t(
								"mini_reg.modal.displayname",
								"Give Yourself A Display Name"
							)}
							name="displayName"
							required
							error={Boolean(error?.displayName)}
							helperText={error?.displayName}
							inputProps={{
								minLength: 3,
								maxLength: 20,
								pattern: "^[a-zA-Z0-9]*$",
							}}
						/>
					</FormControl>

					<FormControl fullWidth sx={{mb: 2}}>
						<Input
							select
							size="small"
							variant="filled"
							disabled={isFormDisabled}
							label={i18n.t("mini_reg.modal.team_supported", "Team Supported")}
							name="favoriteTeam"
							required
							error={Boolean(error?.favoriteTeam)}
							helperText={error?.favoriteTeam}
							onChange={handleFormOnChange}
							defaultValue=""
							InputLabelProps={{
								shrink: true,
							}}
							SelectProps={{
								displayEmpty: true,
								IconComponent: KeyboardArrowDownRoundedIcon,
							}}>
							<MenuItem value="" disabled>
								{i18n.t("mini_reg.modal.select_team", "- Select Team - ")}
							</MenuItem>
							{favoriteTeams.map(({name, code}) => (
								<MenuItem key={code} value={code}>
									{name}
								</MenuItem>
							))}
						</Input>
					</FormControl>

					<FormControl fullWidth sx={{ml: 1}}>
						<Label
							name="terms"
							control={<SMuiCheckbox required />}
							label={
								<div
									/*Disabled due html come from our Loco and must be safety*/
									/*eslint-disable-next-line*/
									dangerouslySetInnerHTML={{
										__html: i18n.t(
											"mini_reg.modal.terms",
											`I have read and accepted the <a href="/help/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>`
										),
									}}
								/>
							}
						/>
					</FormControl>

					<FormControl fullWidth sx={{mb: 1, ml: 1}}>
						<Label
							name="isNotificationsEnabled"
							control={<Switch />}
							label={i18n.t(
								"mini_reg.modal.emails",
								"I consent to receiving email communications related to game."
							)}
						/>
					</FormControl>

					<FormControl fullWidth sx={{mb: 2, ml: 1}}>
						<Label
							name="isNotificationsFromPartnersEnabled"
							control={<Switch />}
							label={i18n.t(
								"mini_reg.modal.partners",
								"I consent to receive email communications from the IIHF related to the game."
							)}
						/>
					</FormControl>

					<FormControl fullWidth>
						<Exist when={error?.common}>
							<ErrorText>{error?.common}</ErrorText>
						</Exist>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							size="large"
							disabled={isSaveButtonDisabled}>
							{i18n.t("mini_reg.modal.get_started", "GET STARTED")}
						</Button>
					</FormControl>
				</form>
			</Container>
		</ModalBase>
	);
});
