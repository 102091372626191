import {AxiosResponse} from "axios";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {IGameBar} from "data/stores/game_bar/game_bar.store";

interface IGetGameBarPayload {
	contestId?: number;
}

export interface IRankingPayload {
	contestId?: number;
	page?: number;
	limit?: number;
}

export interface IRankingResponse {
	ladder: ILadder[];
	user: ILadder | null;
	nextPage: boolean;
}

export interface ILadder {
	userId: number;
	displayName: string;
	prevRank: number | null;
	rank: number | null;
	points: number | null;
	correctPredictionsCount: number | null;
}

export interface ILeagueRankingsPayload {
	leagueId: number;
	contestId?: number;
	page?: number;
	limit?: number;
}

export interface IRankingsResponse {
	ladder: ILadder[];
	user: ILadder | null;
	nextPage: boolean;
}

export type TLeagueRankingsResponse = IApiResponse<IRankingsResponse>;
export type TGameBarResponse = IApiResponse<IGameBar>;
export type TRankingResponse = IApiResponse<IRankingResponse>;

export interface IRankingApiProvider {
	gameBar: (payload?: IGetGameBarPayload) => Promise<AxiosResponse<TGameBarResponse>>;
	ranking: (payload: IRankingPayload) => Promise<AxiosResponse<TRankingResponse>>;
	league: (params: ILeagueRankingsPayload) => Promise<AxiosResponse<TLeagueRankingsResponse>>;
}

@injectable()
export class RankingsApiProvider implements IRankingApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	gameBar = (params?: IGetGameBarPayload) =>
		this._http.get<TGameBarResponse>(`predictor/rank/gamebar`, params);
	ranking = (params: IRankingPayload) =>
		this._http.get<TRankingResponse>(`predictor/rank/leaderboard`, params);
	league = ({leagueId, ...params}: ILeagueRankingsPayload) =>
		this._http.get<TLeagueRankingsResponse>(`predictor/rank/league/${leagueId}/ladder`, params);
}
