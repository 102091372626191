import {makeAutoObservable, observable, reaction} from "mobx";
import {inject, injectable} from "inversify";
import {ChangeEvent} from "react";
import {AxiosError} from "axios";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRankingStore} from "data/stores/ranking/ranking.store";
import type {IContest, IContestsStore} from "data/stores/contests/contests.store";
import {ModalType, RequestState} from "data/enums";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface ILeaderboardController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get selectedContestId(): string;
	get completedContests(): IContest[];

	onLoadMore: () => void;
	contestSelectHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LeaderboardController implements ILeaderboardController {
	@observable private _requestState: RequestState = RequestState.PENDING;
	@observable private _fetchRankingsDisposer?: ReturnType<typeof reaction>;

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get selectedContestId() {
		return this._rankingStore.contestId;
	}

	get completedContests() {
		return this._contestsStore.contestsWithCompleteQuestion;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RankingStore) public _rankingStore: IRankingStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	contestSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const contestId = Number(e.target.value);
		this._rankingStore.setContestId(contestId);
	};

	init() {
		Promise.all([this._contestsStore.fetchContests()]).catch(this.onError);

		this._fetchRankingsDisposer = reaction(
			() => [this.selectedContestId],
			() => {
				this._requestState = RequestState.PENDING;

				void this._rankingStore
					.fetchLeaderboards()
					.then(this.onSuccess)
					.catch(this.onError);
			},
			{fireImmediately: true}
		);
	}

	onLoadMore = () => {
		this._requestState = RequestState.PENDING;

		this._rankingStore.fetchMoreLeaderboards().then(this.onSuccess).catch(this.onError);
	};

	dispose() {
		this._fetchRankingsDisposer?.();
	}
}
