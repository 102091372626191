import React from "react";
import styled from "@emotion/styled";
import {Outlet} from "react-router-dom";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;

const Container = styled.div`
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`;

export const Layout: React.FC = () => {
	return (
		<Wrapper>
			<Header />

			<Container>
				<Outlet />
			</Container>

			<Footer />
		</Wrapper>
	);
};
