export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	ContestsStore: Symbol("ContestsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	RegistrationController: Symbol("RegistrationController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	LandingController: Symbol("LandingController"),
	ModalRegisterController: Symbol("ModalRegisterController"),
	PredictorController: Symbol("PredictorController"),
	RoundSelectorController: Symbol("RoundSelectorController"),
	QuestionsController: Symbol("QuestionsController"),
	HeaderController: Symbol("HeaderController"),
	FavoriteTeamsStore: Symbol("FavoriteTeamsStore"),
	PredictionsStore: Symbol("PredictionsStore"),
	PredictionApiProvider: Symbol("PredictionApiProvider"),
	QuestionController: Symbol("QuestionController"),
	NumericalSliderController: Symbol("NumericalSliderController"),
	DropDownController: Symbol("DropDownController"),
	ActionsController: Symbol("ActionsController"),
	GameBarStore: Symbol("GameBarStore"),
	RankingApiProvider: Symbol("RankingApiProvider"),
	MultipleChoiceController: Symbol("MultipleChoiceController"),
	BannerStore: Symbol("BannerStore"),
	BannerController: Symbol("BannerController"),
	MyLeaguesSearchController: Symbol("MyLeaguesSearchController"),
	JoinLeaguesSearchController: Symbol("JoinLeaguesSearchController"),
	SnackbarController: Symbol("SnackbarController"),
	SnackbarStore: Symbol("SnackbarStore"),
	LeagueManageController: Symbol("LeagueManageController"),
	ModalLeagueInviteController: Symbol("ModalLeagueInviteController"),
	TutorialController: Symbol("TutorialController"),
	TutorialStore: Symbol("TutorialStore"),
	TutorialStepController: Symbol("TutorialStepController"),
	GameBarController: Symbol("GameBarController"),
	MenuStore: Symbol("MenuStore"),
	ModalNextContestController: Symbol("ModalNextContestController"),
	ModalLeaguesController: Symbol("ModalLeaguesController"),
	ModalBoosterController: Symbol("ModalBoosterController"),
	LeaderboardController: Symbol("LeaderboardController"),
	RankingStore: Symbol("RankingStore"),
	LeaderboardComponentController: Symbol("LeaderboardComponentController"),
	SliderStore: Symbol("SliderStore"),
	SummaryViewController: Symbol("SummaryViewController"),
	SummaryViewCardController: Symbol("SummaryViewCardController"),
	LeagueLadderController: Symbol("LeagueLadderController"),
	LeagueRankingsStore: Symbol("LeagueRankingsStore"),
	MenuItemController: Symbol("MenuItemController"),
};
