import styled from "@emotion/styled";
import {FormControl, outlinedInputClasses} from "@mui/material";
import SelectMUI from "@mui/material/Select";
import React from "react";
import {SelectProps} from "@mui/material/Select/Select";
import InputLabel from "@mui/material/InputLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";

export const SelectFormControl = styled(FormControl)`
	background: transparent;
	border-radius: 4px;

	.MuiFilledInput-root {
		background: transparent;
	}

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: var(--primaryColor);
	}

	.MuiFilledInput-root::after {
		border-color: #a3a3a3;
	}

	.MuiSelect-outlined {
		text-align: left;
	}

	.MuiSvgIcon-root {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.87);
	}
`;

SelectFormControl.defaultProps = {
	variant: "outlined",
	fullWidth: true,
};

export const SelectStyled = styled(SelectMUI)`
	color: currentColor;
	border-radius: 4px;

	&.MuiFilledInput-root::before,
	&.MuiFilledInput-root::after {
		border-radius: 3px;
	}
`;

SelectStyled.defaultProps = {
	variant: "outlined",
	fullWidth: true,
};

export const Select: React.FC<Omit<SelectProps, "variant">> = ({
	label,
	defaultValue = "",
	name,
	required,
	children,
	disabled,
	...rest
}) => (
	<SelectFormControl>
		<InputLabel disabled={disabled} required={required}>
			{label}
		</InputLabel>
		<SelectStyled
			defaultValue={defaultValue}
			name={name}
			label={label}
			disabled={disabled}
			required={required}
			IconComponent={KeyboardArrowDownIcon}
			{...rest}>
			{children}
		</SelectStyled>
	</SelectFormControl>
);

export const QuestionSelect = styled(TextField)`
	--TextField-brandBorderColor: ${({theme}) => theme.palette.questionSelect.border};
	--TextField-brandBorderHoverColor: ${({theme}) => theme.palette.questionSelect.border};
	--TextField-brandBorderFocusedColor: ${({theme}) => theme.palette.questionSelect.bg};

	& label.Mui-focused {
		color: var(--TextField-brandBorderFocusedColor);
	}

	.MuiOutlinedInput-root {
		border-radius: 2px;
		height: 44px;

		.MuiOutlinedInput-notchedOutline {
			border-width: 2px;
			border-radius: 8px;
			border-color: var(--TextField-brandBorderColor);
		}

		&:hover .${outlinedInputClasses.notchedOutline} {
			border-color: var(--TextField-brandBorderHoverColor);
		}

		&.Mui-focused .${outlinedInputClasses.notchedOutline} {
			border-color: var(--TextField-brandBorderFocusedColor);
		}

		div[aria-expanded="true"] {
			color: ${({theme}) => theme.palette.questionSelect.openColor};
			z-index: 1;
		}

		div[aria-expanded="true"] ~ fieldset {
			background: var(--TextField-brandBorderFocusedColor);
		}
	}

	.MuiInputLabel-asterisk {
		display: none;
	}

	.MuiFormLabel-root,
	.MuiSelect-select {
		color: ${({theme}) => theme.palette.questionSelect.color};
		font-family: "IIHF 2024 Text", sans-serif;
		font-size: 16px;
		font-weight: 600;
		line-height: 150%;

		&[data-shrink="false"] {
			top: -6px;
		}
	}

	&.select-open {
		.MuiSvgIcon-root {
			fill: ${({theme}) => theme.palette.questionSelect.openArrowBg};
		}

		.MuiFormLabel-root {
			color: white;
		}
	}

	&.selected:not(.select-open) {
		.MuiSelect-select {
			z-index: 1;
			color: ${({theme}) => theme.palette.questionSelect.answeredColor};
		}

		fieldset {
			background: ${({theme}) => theme.palette.questionSelect.answeredBg};
		}

		.${outlinedInputClasses.notchedOutline} {
			border-color: var(--TextField-brandBorderFocusedColor);
		}
	}

	.MuiSvgIcon-root {
		z-index: 1;
		fill: ${({theme}) => theme.palette.questionSelect.arrowBg};
	}
`;
