import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {Layout} from "views/components/layout/layout.component";

const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const JoinLeagues = lazy(retryFailLoad(() => import("views/pages/join_leagues/join_leagues.page")));
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueLadder = lazy(
	retryFailLoad(() => import("views/pages/league_ladder/league_ladder.page"))
);
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueInvite = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.page"))
);
const LeagueAbout = lazy(retryFailLoad(() => import("views/pages/league_about/league_about.page")));
const LeagueManage = lazy(
	retryFailLoad(() => import("views/pages/league_manage/league_manage.page"))
);
const Landing = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const Predictor = lazy(retryFailLoad(() => import("views/pages/predictor/predictor.page")));
const Leaderboard = lazy(retryFailLoad(() => import("views/pages/leaderboard/leaderboard.page")));

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<Layout />}>
			<Route element={<NotAuthOnlyRoute />}>
				<Route index element={<Landing />} />
			</Route>
			<Route element={<PrivateRoute />}>
				<Route path="/predictor" element={<Predictor />} />
				<Route path="/my-account" element={<MyAccount />} />
				<Route path="/leagues" element={<MyLeagues />} />
				<Route path="/leagues/join" element={<JoinLeagues />}>
					<Route index path=":code" element={<JoinLeagues />} />
				</Route>
				<Route path="/leagues/create" element={<CreateLeague />} />
				<Route path="/league/:leagueId" element={<League />}>
					<Route path="ladder" element={<LeagueLadder />} />
					<Route path="invite" element={<LeagueInvite />} />
					<Route path="settings" element={<LeagueSettings />} />
					<Route path="about" element={<LeagueAbout />} />
					<Route path="manage" element={<LeagueManage />} />
				</Route>
				<Route path="/leaderboard" element={<Leaderboard />} />
			</Route>
			<Route path="components" element={<ComponentsReview />} />
			<Route path="help/*" element={<Help />} />
			<Route path="*" element={<NotFound />} />
		</Route>
	</Routes>
);

export default RootRoutes;
