import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import type {AxiosError} from "axios";
import {find, isEqual} from "lodash";
import {useNavigate} from "react-router-dom";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeagues, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeagueStatus, ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {type IContestsStore} from "data/stores/contests/contests.store";
import type {ISnackbarStore} from "data/stores/snackbar/snackbar.store";
import {REDIRECT_PATH} from "data/constants";

export interface IJoinLeagueData extends ILeague {
	startContestName?: string;
}

export interface ILeaguesData extends ILeagues {
	leagues: IJoinLeagueData[];
}

interface IProps {
	code?: string;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IJoinLeaguesController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get leagues(): ILeaguesData;
	get isLoading(): boolean;

	loadMoreMyLeagues: () => void;
	joinLeague: (id: number) => void;
	isJoinInProgress: (id: number) => boolean;
}

@injectable()
export class JoinLeaguesController implements IJoinLeaguesController {
	@observable _requestState = RequestState.PENDING;
	@observable _joiningLeagueId?: number;
	@observable _navigate!: ReturnType<typeof useNavigate>;

	get leagues() {
		return {
			...this._leaguesStore.leaguesForJoin,
			leagues: this._leaguesStore.leaguesForJoin.leagues.map((league) => ({
				...league,
				startContestName: this._contestsStore.getContestById(league.startContestId)?.name,
			})),
		};
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.SnackbarStore) private _snackbarStore: ISnackbarStore
	) {
		makeAutoObservable(this);
	}

	loadMoreMyLeagues = () => {
		this._leaguesStore
			.fetchMoreLeaguesForJoin({limit: 10})
			.then(this.onSuccess)
			.catch(this.onError);
	};

	private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this._joiningLeagueId = undefined;
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._joiningLeagueId = undefined;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	joinLeague = (id: number) => {
		const code = find(this.leagues.leagues, {id})?.code;

		if (!code) return;

		this._requestState = RequestState.PENDING;
		this._joiningLeagueId = id;

		this._leaguesStore
			.joinToLeague({code})
			.then(() => this.redirectToMyLeagueByCode({code}))
			.catch(this.onError);
	};

	isJoinInProgress = (id: number) => {
		return this._joiningLeagueId === id;
	};

	private redirectToMyLeagueByCode = ({
		code,
		replace = false,
	}: {
		code: string;
		replace?: boolean;
	}) => {
		const {leagues} = this._leaguesStore.myLeagues;
		const league = find(leagues, {code});

		if (league) {
			const path = league.status !== LeagueStatus.SCHEDULED ? "ladder" : "about";

			this._navigate(`/league/${league.id}/${path}`, {replace});

			this._snackbarStore.handleOpen(
				this.i18n.t(
					"create_league.create.success_copy",
					`You have successfully joined for a league <b>“{{X}}”</b>`,
					{
						X: league.name,
					}
				)
			);
		}
	};

	init({code, navigate}: IProps) {
		this._navigate = navigate;

		Promise.all([
			this._leaguesStore.fetchLeaguesForJoin({limit: 10}),
			this._contestsStore.fetchContests(),
		])
			.then(this.onSuccess)
			.catch(this.onError);

		if (code) {
			sessionStorage.removeItem(REDIRECT_PATH);

			this._modalsStore.showModal(ModalType.LEAGUE_INVITE, {
				leagueCode: code,
				callback: () => {
					this._leaguesStore
						.joinToLeague({code})
						.then(() => {
							this.redirectToMyLeagueByCode({
								code,
								replace: true,
							});

							this._modalsStore.hideModal();
						})
						.catch(this.onError);
				},
			});
		}
	}

	dispose() {
		this._leaguesStore.clearStore();
	}
}
