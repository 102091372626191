import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {find, first, get, last, minBy, some} from "lodash";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {
	ContestStatus,
	OptionImageType,
	OptionsStructureType,
	QuestionStatus,
	QuestionType,
} from "data/enums";

interface IOptionImage {
	id: number;
	type: OptionImageType;
	pathname: string;
}

export interface IOption {
	id: number;
	value: string;
	isCorrect: boolean | null;
	images: IOptionImage[];
}

export interface IQuestion {
	id: number;
	title: string;
	status: QuestionStatus;
	type: QuestionType;
	lockDate: string;
	options: IOption[];
	image?: IOptionImage;
	optionsStructureType: OptionsStructureType;
}

export interface IContest {
	id: number;
	name: string;
	isPlayoff: boolean;
	status: ContestStatus;
	startDate: string;
	endDate: string | null;
	questions: IQuestion[];
}

export interface IContestsStore {
	get selectedContestId(): number;
	get getIsLoading(): boolean;
	get list(): IContest[];
	get scheduleContests(): IContest[];
	get completedContests(): IContest[];
	get contestsWithCompleteQuestion(): IContest[];
	get playoffContestsWithCompleteQuestion(): IContest[];
	get currentContest(): IContest | undefined;
	get selectedContest(): IContest | undefined;
	get openQuestions(): IQuestion[];

	fetchContests(): Promise<void>;
	setSelectedContestId(contestId: number): void;
	getQuestionById(questionId?: number): IQuestion | undefined;
	getContestById(contestId?: number): IContest | undefined;
	clearStore(): void;
}

@injectable()
export class ContestsStore implements IContestsStore {
	@observable private _selectedContestId: number = 0;
	@observable private _isLoading: boolean = false;
	@observable private _list: IContest[] = [];

	get selectedContestId() {
		return this._selectedContestId;
	}

	get list() {
		return this._list;
	}

	get scheduleContests() {
		return this._list.filter((e) => e.status === ContestStatus.Open);
	}

	get completedContests() {
		return this._list.filter((e) => e.status === ContestStatus.Complete);
	}

	get contestsWithCompleteQuestion() {
		return this._list.filter((contest) =>
			contest.questions.some((question) => question.status === QuestionStatus.Complete)
		);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get currentContest() {
		return this.activeContest || first(this.scheduleContests) || last(this.list);
	}

	get selectedContest() {
		return (
			this._list.find((contest) => contest.id === this._selectedContestId) ||
			this.currentContest
		);
	}

	private get activeContest() {
		const allQuestions: IQuestion[] = this.list
			.map(({questions}) => questions)
			.flat()
			.filter(({status}) => status === QuestionStatus.Open);

		const closestQuestion = minBy(allQuestions, (question) => new Date(question.lockDate));

		return find(this.list, (contest) => some(contest.questions, {id: closestQuestion?.id}));
	}

	get openQuestions() {
		return get(this.selectedContest, "questions", []).filter(
			({status}) => status === QuestionStatus.Open
		);
	}

	get playoffContestsWithCompleteQuestion() {
		return this._list.filter((contest) => {
			if (!contest.isPlayoff) {
				return false;
			}

			return contest.questions.some(
				(question) => question.status === QuestionStatus.Complete
			);
		});
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	async fetchContests() {
		const {data} = await this._jsonProvider.contests();

		runInAction(() => {
			this._list = data;
		});
	}

	setSelectedContestId(contestId: number) {
		this._selectedContestId = contestId;
	}

	getQuestionById = (questionId?: number) => {
		return this.selectedContest?.questions.find(({id}) => id === questionId);
	};

	getContestById = (contestId?: number) => {
		return this._list.find(({id}) => id === contestId);
	};

	clearStore = () => {
		this._selectedContestId = 0;
		this._list = [];
	};
}
