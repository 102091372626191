import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRankingApiProvider} from "data/providers/api/rankings.api.provider";

export interface IGameBar {
	contestRank: number | null;
	contestPoints: number | null;
	overallPoints: number | null;
}

export interface IGameBarStore {
	get gameBar(): IGameBar;

	fetchGameBar: (roundId?: number) => Promise<void>;
	clearStore: () => void;
}

@injectable()
export class GameBarStore implements IGameBarStore {
	@observable private _gameBar: IGameBar = {
		contestRank: null,
		contestPoints: null,
		overallPoints: null,
	};

	get gameBar() {
		return this._gameBar;
	}

	constructor(@inject(Bindings.RankingApiProvider) private _rankingApi: IRankingApiProvider) {
		makeAutoObservable(this);
	}

	fetchGameBar = async (contestId?: number) => {
		const {data} = await this._rankingApi.gameBar({contestId});

		runInAction(() => {
			this._gameBar = data.success;
		});
	};

	clearStore = () => {
		this._gameBar = {
			contestRank: null,
			contestPoints: null,
			overallPoints: null,
		};
	};
}
