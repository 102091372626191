import React from "react";
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import {Exist} from "views/components/exist/exist.component";
import {
	ModalBase,
	ModalContent,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";

const Icon = styled.div`
	width: 52px;
	margin-bottom: 16px;

	svg {
		width: 100%;
		height: auto;
		fill: #e40000;
	}

	@media (min-width: 769px) {
		width: 64px;
	}
`;

const Title = styled(ModalTitle)`
	color: ${({theme}) => theme.palette.modal.secondColor};
	margin-bottom: 8px;
`;

const Desc = styled(ModalDesc)`
	margin-bottom: 16px;
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close}>
			<ModalContent>
				<Icon>
					<ErrorIcon />
				</Icon>

				<Title>{modalContent?.title ?? i18n.t("modal.error.title", "Error")}</Title>

				<Exist when={modalContent?.message}>
					<Desc>{modalContent?.message}</Desc>
				</Exist>

				<Button variant="contained" fullWidth onClick={close}>
					{i18n.t("modal.error.close", "Close")}
				</Button>
			</ModalContent>
		</ModalBase>
	);
});
