import {useEffect, useRef, useState} from "react";
import {Countdown} from "data/utils";

export const useTimer = (date: string) => {
	const timerRef = useRef(new Countdown(new Date(date)));

	const [{days, hours, minutes, seconds}, setTime] = useState({
		days: "00",
		hours: "00",
		minutes: "00",
		seconds: "00",
	});

	useEffect(() => {
		const timer = timerRef.current;

		timer
			.clear()
			.updateDate(new Date(date))
			.onTick((days, hours, minutes, seconds) =>
				setTime({
					days: days.toString().padStart(2, "0"),
					hours: hours.toString().padStart(2, "0"),
					minutes: minutes.toString().padStart(2, "0"),
					seconds: seconds.toString().padStart(2, "0"),
				})
			)
			.run();

		return () => {
			timer.clear();
		};
	}, [timerRef, date]);

	if (days !== "00") {
		return `${days}D:${hours}H:${minutes}M`;
	}

	return `${hours}H:${minutes}M:${seconds}S`;
};
