import {makeAutoObservable, observable, reaction} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {ChangeEvent} from "react";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContest, IContestsStore} from "data/stores/contests/contests.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeagueType, ModalType, RankStatus, RequestState} from "data/enums";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILeagueRankingsStore} from "data/stores/league_rankings/league_rankings.store";
import {ILadder} from "data/providers/api/rankings.api.provider";
import {getRankStatus} from "data/utils/getRankStatus";
import type {IUserStore} from "data/stores/user/user.store";

export interface ILadderData extends ILadder {
	rankStatus: RankStatus | null;
	cn: string;
}

interface IProps {
	leagueId: number;
}

export interface ILeagueLadderController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get isLoading(): boolean;
	get contests(): IContest[];
	get contestId(): number;
	get rankings(): ILadderData[];
	get isOverall(): boolean;
	get nextPage(): boolean;

	loadMore: () => void;
	contestSelectHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LeagueLadderController implements ILeagueLadderController {
	@observable private _leagueId?: number;
	@observable private _requestState?: RequestState = RequestState.PENDING;
	@observable private _fetchRankingsDisposer?: ReturnType<typeof reaction>;

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get contests() {
		if (this.league?.class === LeagueType.OVERALL_PLAYOFF) {
			return this._contestsStore.playoffContestsWithCompleteQuestion;
		}

		return this._contestsStore.contestsWithCompleteQuestion;
	}

	get contestId() {
		return this._leagueRankingsStore.contestId;
	}

	get rankings() {
		return this._leagueRankingsStore.list.map((ranking) => ({
			...ranking,
			rankStatus: getRankStatus(ranking),
			cn: ranking.userId === this._userStore.user?.id ? "me" : "",
		}));
	}

	get isOverall() {
		return !this.contestId;
	}

	get nextPage() {
		return this._leagueRankingsStore.nextPage;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeagueRankingsStore) private _leagueRankingsStore: ILeagueRankingsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	private get params() {
		const contestId = this.isOverall ? undefined : this.contestId;

		return {
			contestId,
			leagueId: this._leagueId!,
			limit: 10,
		};
	}

	private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	init({leagueId}: IProps) {
		this._leagueId = leagueId;

		Promise.all([this._contestsStore.fetchContests()]).catch(this.onError);

		this._fetchRankingsDisposer = reaction(
			() => [this.contestId],
			() => {
				this._requestState = RequestState.PENDING;

				void this._leagueRankingsStore
					.fetchLeagueRankings(this.params)
					.then(this.onSuccess)
					.catch(this.onError);
			},
			{fireImmediately: true}
		);
	}

	contestSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const contestId = Number(e.target.value);
		this._leagueRankingsStore.setContestId(contestId);
	};

	loadMore = () => {
		this._requestState = RequestState.PENDING;

		this._leagueRankingsStore
			.loadMoreLeagueRankings(this.params)
			.then(this.onSuccess)
			.catch(this.onError);
	};

	dispose() {
		this._fetchRankingsDisposer?.();
		this._leagueRankingsStore.clearStore();
	}
}
