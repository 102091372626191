import styled from "@emotion/styled";
import {BaseCheckbox} from "views/components/form/checkbox.component";
import {FormLabel} from "@mui/material";

export const RadioButton = styled(BaseCheckbox)``;

RadioButton.defaultProps = {
	type: "radio",
};

export const RadioLabel = styled(FormLabel)`
	&& {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: ${({theme}) => theme.palette.leaguePage.color};
		font-size: 16px;
		font-weight: 600;
		line-height: 150%;
	}
`;
