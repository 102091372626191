import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore, IQuestion} from "data/stores/contests/contests.store";

export interface IBannerStore {
	get question(): IQuestion | undefined;

	setQuestionId: (questionId?: number) => void;
}

@injectable()
export class BannerStore implements IBannerStore {
	@observable _questionId: number = 0;

	get question() {
		return this._contestsStore.getQuestionById(this._questionId);
	}

	constructor(@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore) {
		makeAutoObservable(this);
	}

	setQuestionId = (questionId: number = 0) => {
		this._questionId = questionId;
	};
}
