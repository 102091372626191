import {makeAutoObservable, observable, reaction} from "mobx";
import {inject, injectable} from "inversify";
import {useRef} from "react";
import Slider from "react-slick";
import {ViewController} from "data/types/structure";
import type {IContestsStore, IQuestion} from "data/stores/contests/contests.store";
import {Bindings} from "data/constants/bindings";
import type {IBannerStore} from "data/stores/banner/banner.store";
import type {ISliderStore} from "data/stores/slider/slider.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";

interface IParams {
	sliderRef: ReturnType<typeof useRef<Slider | null>>;
}

export interface IQuestionsController extends ViewController<IParams> {
	get questions(): IQuestion[];
	get index(): number;
	get prevArrowCN(): string;
	get nextArrowCN(): string;
	get isDisabled(): boolean;
	get isSummaryView(): boolean;

	afterChange: (currentSlide: number) => void;
}

@injectable()
export class QuestionsController implements IQuestionsController {
	@observable private _sliderRef?: ReturnType<typeof useRef<Slider | null>>;
	@observable private _disposer?: ReturnType<typeof reaction>;

	get contest() {
		return this._contestsStore.selectedContest;
	}

	get questions() {
		return this.contest?.questions || [];
	}

	get index() {
		return this._sliderStore.index;
	}

	get prevArrowCN() {
		return !this.index ? "disabled" : "";
	}

	get nextArrowCN() {
		return this.index === this.questions.length - 1 ? "disabled" : "";
	}

	get isDisabled() {
		return this._sliderStore.isAnimationPlaying;
	}

	get isSummaryView() {
		return this._predictionsStore.isSummaryView;
	}

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.BannerStore) private _bannerStore: IBannerStore,
		@inject(Bindings.SliderStore) private _sliderStore: ISliderStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	init(params: IParams) {
		this._sliderRef = params.sliderRef;

		this._disposer = reaction(
			() => this.contest?.id,
			() => {
				this._sliderRef?.current?.slickGoTo(0);
			}
		);
	}

	onChange(params: IParams) {
		this.init(params);
	}

	afterChange = (currentSlide: number) => {
		this._sliderStore.setIndex(currentSlide);

		const question = this.questions[currentSlide];

		this._bannerStore.setQuestionId(question?.id);
	};

	dispose() {
		this._disposer?.();
		this._sliderStore.setIsAnimationPlaying(false);
		this._sliderStore.setIndex(0);
	}
}
