import {inject, injectable} from "inversify";
import {AxiosResponse} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {IPrediction} from "data/stores/predictions/predictions.store";
import {Bindings} from "data/constants/bindings";

export interface IGetPredictionPayload {
	contestId: number;
	signal?: AbortSignal;
}

export interface IPredictionResponse {
	predictions: IPrediction[];
	nextContestIdWithMissingPredictions: number | null;
}

type TPredictionResponse = IApiResponse<IPredictionResponse>;

export interface IPredictionForSave {
	questionId: number;
	optionId: number;
	isBoosted: boolean;
}

export interface ISavePredictionPayload {
	contestId: number;
	predictions: IPredictionForSave[];
}

export interface IPredictionApiProvider {
	get: (payload: IGetPredictionPayload) => Promise<AxiosResponse<TPredictionResponse>>;
	save: (payload: ISavePredictionPayload) => Promise<AxiosResponse<TPredictionResponse>>;
}

@injectable()
export class PredictionApiProvider implements IPredictionApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	get = ({signal, contestId}: IGetPredictionPayload) =>
		this._http.get<TPredictionResponse>(
			`predictor/contest/${contestId}/predictions/show`,
			undefined,
			{
				signal,
			}
		);

	save = ({contestId, predictions}: ISavePredictionPayload) =>
		this._http.post<TPredictionResponse>(
			`predictor/contest/${contestId}/predictions/save-multiple`,
			{predictions}
		);
}
