import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import React from "react";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {ModalType, RequestState} from "data/enums";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {type IUserStore} from "data/stores/user/user.store";
import type {
	IFavoriteTeams,
	IFavoriteTeamsStore,
} from "data/stores/favorite_teams/favorite_teams.store";

interface IRegisterForm extends HTMLFormElement {
	displayName: HTMLInputElement;
	favoriteTeam: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
	isNotificationsFromPartnersEnabled: HTMLInputElement;
	terms: HTMLInputElement;
}

export interface IModalRegisterController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get error(): Record<string, string> | null;
	get isFormDisabled(): boolean;
	get favoriteTeams(): IFavoriteTeams[];
	get isSaveButtonDisabled(): boolean;

	close: () => void;
	handleFormSubmit: (event: React.SyntheticEvent<IRegisterForm>) => void;
	handleFormOnChange: () => void;
}

@injectable()
export class ModalRegisterController implements IModalRegisterController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	get isSaveButtonDisabled() {
		return Boolean(this.isFormDisabled || this.error);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.REGISTER;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get favoriteTeams() {
		return this._favoriteTeamsStore.list;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.FavoriteTeamsStore) private _favoriteTeamsStore: IFavoriteTeamsStore
	) {
		makeAutoObservable(this);
	}

	async init() {
		try {
			await this._favoriteTeamsStore.fetchFavoriteTeams();
		} catch (e) {
			this.onError(e as AxiosError<IApiResponse>);
		}
	}

	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this.reportError(extractErrorMessage(error));
	};

	handleFormSubmit = async (event: React.SyntheticEvent<IRegisterForm>) => {
		try {
			event.preventDefault();
			const {
				displayName,
				favoriteTeam,
				isNotificationsEnabled,
				terms,
				isNotificationsFromPartnersEnabled,
			} = event.currentTarget;

			if (!displayName.checkValidity()) {
				return this.reportError(
					this.i18n.t(
						"mini_reg.modal.display_name_error",
						"Please provide a valid display name"
					),
					"displayName"
				);
			}

			if (!favoriteTeam.checkValidity()) {
				return this.reportError(
					this.i18n.t(
						"mini_reg.modal.favorite_team_error",
						"Please select a favorite team"
					),
					"favoriteTeam"
				);
			}

			if (!terms.checkValidity()) {
				return this.reportError(
					this.i18n.t("mini_reg.modal.terms_error", "Please select a terms"),
					""
				);
			}

			const iihfUserId = this.modalContent?.id;
			const verify = this.modalContent?.verify;

			if (!iihfUserId || !verify) {
				throw new Error(
					this.i18n.t("mini_reg.modal.sso_params_error", "SSO query params is invalid")
				);
			}

			await this._userStore.register({
				iihfUserId,
				verify,
				displayName: displayName.value,
				isNotificationsEnabled: isNotificationsEnabled.checked,
				isNotificationsFromPartnersEnabled: isNotificationsFromPartnersEnabled.checked,
				favouriteTeam: favoriteTeam.value,
			});

			this.close();
		} catch (e) {
			this.onError(e as AxiosError<IApiResponse>);
		}
	};

	close = () => {
		this._modalsStore.hideModal();
	};
}
