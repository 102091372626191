import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore, IQuestion} from "data/stores/contests/contests.store";
import type {IPrediction, IPredictionsStore} from "data/stores/predictions/predictions.store";
import {OptionsStructureType, QuestionStatus} from "data/enums";
import {get} from "lodash";

export enum QuestionStyleType {
	TwoAnswers = "two_answers",
	ThreeAnswers = "three_answers",
	FourAnswersList = "four_answers_list",
	FourAnswersGrid = "four_answers_grid",
	SixAnswersGrid = "six_answers_grid",
}

interface IProps {
	id: number;
}

export interface IMultipleChoiceController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get question(): IQuestion | undefined;
	get prediction(): IPrediction | undefined;
	get hasPrediction(): boolean;
	get isDisabled(): boolean;
	get styleType(): QuestionStyleType;

	getOptionCN: (optionId: number) => string;
	getPoints: (optionId: number) => number | undefined;
	setPredictionAnswer: (optionId: number) => void;
}

@injectable()
export class MultipleChoiceController implements IMultipleChoiceController {
	@observable private _id: number = 0;

	get question() {
		return this._contestsStore.getQuestionById(this._id);
	}

	get prediction() {
		return this._predictionsStore.getPredictionByQuestionId(this._id);
	}

	get hasPrediction() {
		return Boolean(this.prediction?.optionId);
	}

	get isDisabled() {
		return this.question?.status !== QuestionStatus.Open;
	}

	get styleType() {
		const options = get(this.question, "options", []);

		if (options.length === 2) {
			return QuestionStyleType.TwoAnswers;
		}

		if (options.length === 3) {
			return QuestionStyleType.ThreeAnswers;
		}

		if (options.length === 4) {
			if (this.question?.optionsStructureType === OptionsStructureType.Grid) {
				return QuestionStyleType.FourAnswersGrid;
			}

			return QuestionStyleType.FourAnswersList;
		}

		if (options.length === 6) {
			return QuestionStyleType.SixAnswersGrid;
		}

		return QuestionStyleType.TwoAnswers;
	}

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._id = param.id;
	}

	getOptionCN = (optionId: number) => {
		let cn = "";

		if (this.question?.status === QuestionStatus.Locked) {
			cn += "locked";
		}

		const isSelected = this.prediction?.optionId === optionId;

		if (isSelected) {
			cn += " selected";
		}

		const isComplete = this.question?.status === QuestionStatus.Complete;

		if (isComplete) {
			cn += " completed";

			if (this.prediction?.isCorrect) {
				cn += " correct";
			} else {
				cn += " un-correct";
			}

			if (this.question?.options.find(({id, isCorrect}) => id === optionId && isCorrect)) {
				cn += " right-answer";
			}
		}

		return cn;
	};

	getPoints = (optionId: number) => {
		if (this.question?.status !== QuestionStatus.Complete) {
			return;
		}

		const isSelected = this.prediction?.optionId === optionId;

		if (!isSelected) {
			return;
		}

		return this.prediction?.points;
	};

	setPredictionAnswer = (optionId: number) => {
		if (this.question?.status !== QuestionStatus.Open) {
			return;
		}

		this._predictionsStore.setPredictionAnswer(this.question.id, optionId);
	};
}
