import {AxiosError} from "axios";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";

const NOT_AUTHORIZED_STATUS_CODE = 401;
const USER_NOT_REGISTERED = 400;

export interface ISessionController extends ViewController {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	@observable _isSessionChecked = false;

	private get urlSearchParams() {
		return new URLSearchParams(window.location.search);
	}

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private ssoRegister(id: string, verify: string) {
		this._modalsStore.showModal(ModalType.REGISTER, {
			id,
			verify,
		});
	}

	private async ssoLogin() {
		// Check SSO query params in url If get 401 error
		const id = this.urlSearchParams.get("id");
		const verify = this.urlSearchParams.get("verify");

		if (!id || !verify) {
			return;
		}

		try {
			await this._userStore.login({verify, iihfUserId: id});
		} catch (e) {
			if ((e as AxiosError<IApiResponse>)?.response?.status === USER_NOT_REGISTERED) {
				this.ssoRegister(id, verify);
			} else {
				this._modalsStore.showModal(ModalType.ERROR, {
					message: extractErrorMessage(e as AxiosError<IApiResponse>),
				});
			}
		}
	}

	async init() {
		try {
			await this._userStore.requestUser();
		} catch (e) {
			if ((e as AxiosError<IApiResponse>)?.response?.status === NOT_AUTHORIZED_STATUS_CODE) {
				await this.ssoLogin();
			}
		} finally {
			runInAction(() => {
				this._isSessionChecked = true;
			});
		}
	}
}
