import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {get, isBoolean, sample} from "lodash";
import {isCancel} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {
	IPredictionApiProvider,
	IPredictionForSave,
} from "data/providers/api/prediction.api.provider";
import {ContestStatus, QuestionStatus} from "data/enums";

export interface IPrediction {
	questionId: number;
	optionId: number | null;
	isCorrect: boolean | null;
	isBoosted: boolean;
	points: number;
}

export interface IPredictionsStore {
	get predictions(): IPrediction[];
	get hasPredictions(): boolean;
	get hasAllPredictions(): boolean;
	get openQuestionPredictions(): IPrediction[];
	get predictionsForSave(): IPredictionForSave[];
	get hasBoostedPrediction(): boolean;
	get isBoosterDisabled(): boolean;
	get isSummaryView(): boolean;
	get hasChanges(): boolean;

	fetchPredictions: (contestId: number) => Promise<void>;
	getPredictionByQuestionId: (questionId: number) => IPrediction | undefined;
	setPredictionAnswer: (questionId: number, optionId: number | null, isBoosted?: boolean) => void;
	savePredictions: () => Promise<number | null>;
	boostRandomQuestion: () => Promise<number | null>;
	setIsSummaryView: (isSummaryView: boolean) => void;
	clearStore: () => void;
}

@injectable()
export class PredictionsStore implements IPredictionsStore {
	@observable private _abortController?: AbortController;
	@observable private _predictions: IPrediction[] = [];
	@observable private _isSummaryView = false;
	@observable private _hasChanges = false;

	get predictions() {
		return this._predictions;
	}

	get hasPredictions() {
		return Boolean(this.predictions.length);
	}

	get hasAllPredictions() {
		const tournaments = this._contestsStore.selectedContest?.questions || [];
		return tournaments.every(({id}) => this.getPredictionByQuestionId(id)?.optionId);
	}

	get openQuestionPredictions() {
		return this._contestsStore.openQuestions
			.map(({id}) => this.getPredictionByQuestionId(id))
			.filter((prediction) => prediction?.optionId) as IPrediction[];
	}

	get isBoosterDisabled() {
		const boosterQuestion = this._contestsStore.getQuestionById(
			this.boostedPrediction?.questionId
		);

		if (!boosterQuestion) {
			return false;
		}

		return boosterQuestion.status !== QuestionStatus.Open;
	}

	get boostedPrediction() {
		return this._predictions.find(({isBoosted}) => isBoosted);
	}

	get hasBoostedPrediction() {
		return Boolean(this.boostedPrediction);
	}

	get isSummaryView() {
		return this._isSummaryView;
	}

	get hasChanges() {
		return this._hasChanges;
	}

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.PredictionApiProvider) private _predictionApi: IPredictionApiProvider
	) {
		makeAutoObservable(this);
	}

	fetchPredictions = async (contestId: number) => {
		try {
			this._abortController?.abort();

			this._abortController = new AbortController();

			const {data} = await this._predictionApi.get({
				contestId,
				signal: this._abortController.signal,
			});

			const predictions = data.success.predictions;
			const contest = this._contestsStore.getContestById(contestId);

			runInAction(() => {
				this._predictions = predictions;

				const isCompleteContest = contest?.status === ContestStatus.Complete;
				const hasAllPredictions = get(contest, "questions", []).every(({id, status}) => {
					const hasPrediction = predictions.some(({questionId}) => questionId === id);
					const isLocked = status !== QuestionStatus.Open;

					return hasPrediction || isLocked;
				});

				this._isSummaryView = isCompleteContest || hasAllPredictions;
				this._hasChanges = false;
			});
		} catch (e) {
			if (isCancel(e)) return;
			throw e;
		}
	};

	getPredictionByQuestionId = (questionId: number) => {
		return this._predictions.find((prediction) => prediction.questionId === questionId);
	};

	private addPrediction = (prediction: IPrediction) => {
		this._predictions = [...this._predictions, prediction];

		return prediction;
	};

	private removeBooster = () => {
		this._predictions = this._predictions.map((prediction) => ({
			...prediction,
			isBoosted: false,
		}));
	};

	setPredictionAnswer = (
		questionId: number,
		optionId: number | null = null,
		isBoosted?: boolean
	) => {
		if (isBoosted) {
			this.removeBooster();
		}

		const prediction =
			this.getPredictionByQuestionId(questionId) ||
			this.addPrediction({
				questionId,
				isCorrect: null,
				isBoosted: isBoosted || false,
				optionId,
				points: 0,
			});

		if (optionId) {
			prediction.optionId = optionId;
		}

		if (isBoolean(isBoosted)) {
			prediction.isBoosted = isBoosted;
		}

		this._hasChanges = true;
	};

	get predictionsForSave() {
		return this.openQuestionPredictions
			.map(({questionId, isBoosted, optionId}) => ({
				questionId,
				isBoosted,
				optionId,
			}))
			.filter(({optionId}) => optionId) as IPredictionForSave[];
	}

	savePredictions = async () => {
		const contestId = this._contestsStore.selectedContest?.id;

		if (!contestId) {
			return null;
		}
		const {data} = await this._predictionApi.save({
			predictions: this.predictionsForSave,
			contestId,
		});

		runInAction(() => {
			this._predictions = data.success.predictions;
			this._isSummaryView = true;
			this._hasChanges = false;
		});

		return data.success.nextContestIdWithMissingPredictions;
	};

	boostRandomQuestion = async () => {
		const prediction = sample(this._predictions);

		if (!prediction || this.hasBoostedPrediction) {
			return null;
		}

		prediction.isBoosted = true;

		return this.savePredictions();
	};

	setIsSummaryView = (isSummaryView: boolean) => {
		this._isSummaryView = isSummaryView;
	};

	clearStore = () => {
		this._predictions = [];
		this._hasChanges = false;
	};
}
