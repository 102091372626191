import {action, computed, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore, ILeagueUsers} from "data/stores/leagues/leagues.store";
import type {IContest, IContestsStore} from "data/stores/contests/contests.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IApiResponse} from "data/services/http";
import type {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {isEqual} from "lodash";
import {ContestStatus, LeagueStatus, ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";

interface IProps {
	leagueId: number;
}

export interface ILeagueManageController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get userID(): number;
	get league(): ILeague | null;
	get contests(): IContest[];
	get isLoading(): boolean;
	get isLeaguePresenceRequestLoading(): boolean;
	get isLeagueUsersRequestLoading(): boolean;
	get isLeagueStarted(): boolean;
	get leagueUsers(): ILeagueUsers | null;
	get isShowTable(): boolean;

	loadMoreUsers: () => void;
	removeUser: (userId: number) => void;
}

@injectable()
export class LeagueManageController implements ILeagueManageController {
	@observable private _leagueId?: number;

	@observable private _requestsStates = {
		general: RequestState.PENDING,
		leaguePresence: RequestState.IDLE,
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
	};

	private get leagueID() {
		return this._leagueId!;
	}

	get userID() {
		return this._userStore.user!.id;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get leagueUsers() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueUsersByLeagueId(this._leagueId);
	}

	@computed get contests() {
		return this._contestsStore.list.filter((contest) => {
			const isScheduled = isEqual(contest.status, ContestStatus.Open);
			const isLeagueStartRound = isEqual(this.league?.startContestId, contest.id);

			return isScheduled || isLeagueStartRound;
		});
	}

	get isLoading() {
		return isEqual(this._requestsStates.general, RequestState.PENDING);
	}

	get isLeaguePresenceRequestLoading() {
		return isEqual(this._requestsStates.leaguePresence, RequestState.PENDING);
	}

	get isLeagueUsersRequestLoading() {
		return isEqual(this._requestsStates.leagueUsers, RequestState.PENDING);
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	get isShowTable() {
		return Boolean(this.leagueUsers?.users.length);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private createSuccessHandlerOf = (type: keyof typeof this._requestsStates) => () => {
		this._requestsStates[type] = RequestState.SUCCESS;
	};

	@action private createErrorHandlerOf =
		(type: keyof typeof this._requestsStates) => (error: AxiosError<IApiResponse>) => {
			this._requestsStates[type] = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			});
		};

	@action private setPendingState = (type: keyof typeof this._requestsStates) => {
		this._requestsStates[type] = RequestState.PENDING;
	};

	@action loadMoreUsers = () => {
		this.setPendingState("leagueUsers");

		this._leaguesStore
			.fetchMoreLeagueUsers({leagueId: this.leagueID})
			.then(this.createSuccessHandlerOf("leagueUsers"))
			.catch(this.createErrorHandlerOf("leagueUsers"));
	};

	@action removeUser = (userId: number) => {
		const user = this.leagueUsers?.users.find((user) => user.userId === userId);

		if (!user) {
			return;
		}

		this._modalsStore.showModal(ModalType.CONFIRM, {
			title: this.i18n.t("remove_league.modal.header", "Are You Sure?"),
			message: this.i18n.t(
				"remove_league.modal.copy",
				`You are removing <b>{{X}}</b>. This action cannot be reversed.`,
				{X: user.displayName}
			),
			callback: () => {
				this._modalsStore.hideModal();
				this.setPendingState("removeUser");

				this._leaguesStore
					.removeUserFromLeague({userId, leagueId: this.leagueID})
					.then(this.createSuccessHandlerOf("removeUser"))
					.catch(this.createErrorHandlerOf("removeUser"));
			},
			confirmBtnText: this.i18n.t("remove_league.modal.yes_btn", "Yes, Remove"),
			cancelBtnText: this.i18n.t("remove_league.modal.no_btn", "No, Cancel"),
		});
	};

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;

		Promise.all([
			this._contestsStore.fetchContests(),
			this._leaguesStore.fetchLeagueUsers({leagueId}),
		])
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));
	}
}
