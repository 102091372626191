import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContest, IContestsStore} from "data/stores/contests/contests.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ChangeEvent} from "react";
import type {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeaguePrivacy, ModalType, RequestState} from "data/enums";
import {debounce, first, identity, isEqual, values} from "lodash";
import {extractErrorMessage} from "data/utils";
import {type ISnackbarStore} from "data/stores/snackbar/snackbar.store";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	leagueName: string;
	startId: number;
	privacy: LeaguePrivacy;
}

export interface ICreateLeagueController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get contests(): IContest[];
	get isLoading(): boolean;
	get isCreateButtonDisabled(): boolean;
	get isFormDisabled(): boolean;
	get formValue(): IFormValue;
	get error(): Record<string, string> | null;

	handleCreateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	startRoundChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class CreateLeagueController implements ICreateLeagueController {
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private _roundsDisposer?: ReturnType<typeof reaction>;
	@observable private _requestState = RequestState.PENDING;
	@observable private _formValue: IFormValue = {
		leagueName: "",
		startId: 0,
		privacy: LeaguePrivacy.PRIVATE,
	};
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	get contests() {
		return this._contestsStore.scheduleContests;
	}

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isFormDisabled() {
		return Boolean(this.isLoading);
	}

	get isCreateButtonDisabled() {
		return this.isFormDisabled || !this.isFormValid;
	}

	@computed
	private get isFormValid() {
		return values(this._formValue).every(identity);
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.SnackbarStore) private _snackbarStore: ISnackbarStore
	) {
		makeAutoObservable(this);
	}

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;
	}

	private clearError() {
		this._errorMsg = null;
		this._errorPlace = "";
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onSuccessLeagueCreate = (league: ILeague) => {
		this._requestState = RequestState.SUCCESS;

		this._navigate?.(`/league/${league.id}/invite`);

		this._snackbarStore.handleOpen(
			this.i18n.t(
				"join_leagues.banner.success",
				`Your league <b>“{{X}}”</b> has been created successfully`,
				{
					X: league.name,
				}
			)
		);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	handleCreateLeague = () => {
		this._requestState = RequestState.PENDING;

		const {leagueName, startId, privacy} = this._formValue;

		this._leaguesStore
			.createLeague({
				privacy,
				startContestId: startId,
				name: leagueName,
			})
			.then(this.onSuccessLeagueCreate)
			.catch(this.onError);
	};

	private checkLeagueName = debounce(async (name: string) => {
		try {
			await this._leaguesStore.checkLeagueName(name);

			this.clearError();
		} catch (e) {
			this.reportError(extractErrorMessage(e as AxiosError<IApiResponse>), "leagueName");
		}
	}, 300);

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		this.clearError();

		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;

		void this.checkLeagueName(leagueName.value);
	};

	startRoundChange = (event: ChangeEvent<HTMLInputElement>) => {
		this._formValue.startId = Number(event.target.value);
	};

	@action init(param: IProps) {
		this._navigate = param.navigate;

		this._contestsStore.fetchContests().then(this.onSuccess).catch(this.onError);

		this._roundsDisposer = reaction(
			() => this.contests,
			() => {
				const roundID = first(this.contests)?.id;

				if (roundID) {
					this._formValue.startId = roundID;
				}
			}
		);
	}

	dispose() {
		this._roundsDisposer?.();
	}
}
