import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {inject, injectable} from "inversify";
import type {AxiosError} from "axios";
import {isEqual} from "lodash";
import {useLocation, useNavigate} from "react-router-dom";
import {ChangeEvent} from "react";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeagueType, ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import type {IContest, IContestsStore} from "data/stores/contests/contests.store";
import type {ILeagueRankingsStore} from "data/stores/league_rankings/league_rankings.store";

interface IProps {
	leagueId: number;
	location: ReturnType<typeof useLocation>;
	navigate: ReturnType<typeof useNavigate>;
	isMobile: boolean;
}

interface ITab {
	path: string;
	name: string;
}

export interface ILeagueController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get isLoading(): boolean;
	get isCommissioner(): boolean;
	get tabs(): ITab[];
	get activeIndex(): number;
	get contests(): IContest[];
	get contestId(): number;
	get isShowContestsSelect(): boolean;

	contestSelectHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LeagueController implements ILeagueController {
	@observable private _location?: ReturnType<typeof useLocation>;
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private _fetchLeagueDisposer?: ReturnType<typeof reaction>;
	@observable private _leagueId?: number;
	@observable private _requestState = RequestState.PENDING;
	@observable private _isMobile: boolean = false;

	get tabs() {
		if (!this.league) return [];

		const id = this.league.id;

		const tabs = [
			{
				path: `/league/${id}/ladder`,
				name: this.i18n.t("league.sub_nav.ladder", "Ladder"),
			},
		];

		if ([LeagueType.OVERALL_PLAYOFF, LeagueType.FAN].includes(this.league.class)) {
			return tabs;
		}

		if (this.isCommissioner) {
			tabs.push({
				path: `/league/${id}/manage`,
				name: this.i18n.t("league.sub_nav.manage", "Manage"),
			});

			tabs.push({
				path: `/league/${id}/settings`,
				name: this.i18n.t("league.sub_nav.settings", "Settings"),
			});
		} else {
			tabs.push({
				path: `/league/${id}/invite`,
				name: this.i18n.t("league.sub_nav.invites", "Invite"),
			});

			tabs.push({
				path: `/league/${id}/about`,
				name: this.i18n.t("league.sub_nav.about", "About"),
			});
		}

		return tabs;
	}

	@computed get activeIndex() {
		const index = this.tabs.findIndex((tab) => isEqual(tab.path, this._location?.pathname));

		return index === -1 ? 0 : index;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isCommissioner() {
		return isEqual(this.league?.leagueManager?.userId, this._userStore.user!.id);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get contests() {
		if (this.league?.class === LeagueType.OVERALL_PLAYOFF) {
			return this._contestsStore.playoffContestsWithCompleteQuestion;
		}

		return this._contestsStore.contestsWithCompleteQuestion;
	}

	get contestId() {
		return this._leagueRankingsStore.contestId;
	}

	get isShowContestsSelect() {
		return this._location?.pathname.split("/").pop() === "ladder" && !this._isMobile;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LeagueRankingsStore) private _leagueRankingsStore: ILeagueRankingsStore
	) {
		makeAutoObservable(this);
	}

	@action onSuccessLeagueRequest = () => {
		this._requestState = RequestState.SUCCESS;

		const hasValidTab = this.tabs.some((tab) => isEqual(tab.path, this._location?.pathname));

		if (!hasValidTab) {
			const path = this.isCommissioner ? "settings" : "about";
			this._navigate?.(`/league/${this._leagueId!}/${path}`, {replace: true});
		}
	};

	@action onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action onChange({leagueId, navigate, location, isMobile}: IProps) {
		this._leagueId = leagueId;
		this._location = location;
		this._navigate = navigate;
		this._isMobile = isMobile;
	}

	@action contestSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const contestId = Number(e.target.value);
		this._leagueRankingsStore.setContestId(contestId);
	};

	@action init(params: IProps) {
		this.onChange(params);

		this._fetchLeagueDisposer = reaction(
			() => this._leagueId,
			() => {
				if (!this._leagueId) return;

				this._requestState = RequestState.PENDING;

				void this._leaguesStore
					.fetchLeague({leagueId: this._leagueId})
					.then(this.onSuccessLeagueRequest)
					.catch(this.onError);
			},
			{fireImmediately: true}
		);
	}

	dispose() {
		this._fetchLeagueDisposer?.();
	}
}
