import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRankingStore} from "data/stores/ranking/ranking.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {getRankStatus} from "data/utils/getRankStatus";
import {ILadderData} from "views/pages/league_ladder/league_ladder.controller";

export interface ILeaderboardComponentController extends ViewController {
	readonly i18n: ILocalizationStore;

	get leaderboard(): ILadderData[];
	get isUserInTop(): boolean;
	get isUserListed(): boolean;
	get nextPage(): boolean;
	get isOverall(): boolean;
	get userRank(): ILadderData | null;
	get user(): IUser | undefined;
}

@injectable()
export class LeaderboardComponentController implements ILeaderboardComponentController {
	get leaderboard() {
		return this._rankingStore.list.map((ranking) => ({
			...ranking,
			rankStatus: getRankStatus(ranking),
			cn: ranking.userId === this._userStore.user?.id ? "me" : "",
		}));
	}

	get isUserInTop() {
		return this._rankingStore.isUserInTop;
	}

	get isUserListed() {
		return this._rankingStore.isUserListed;
	}

	get userRank() {
		const userRank = this._rankingStore.userRank;

		if (!userRank || this.isUserInTop) {
			return null;
		}

		return {
			...userRank,
			rankStatus: getRankStatus(userRank),
			cn: "me sticky",
		};
	}

	get nextPage() {
		return this._rankingStore.nextPage;
	}

	get isOverall() {
		return !Number(this._rankingStore.contestId);
	}

	get user() {
		return this._userStore.user;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RankingStore) public _rankingStore: IRankingStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}
}
