import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {ContestsStore, type IContestsStore} from "data/stores/contests/contests.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	type ICreateLeagueController,
	CreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {
	type IModalRegisterController,
	ModalRegisterController,
} from "views/components/modals/modal_register/modal_register.controller";
import {
	type IPredictorController,
	PredictorController,
} from "views/pages/predictor/predictor.controller";
import {
	type IRoundSelectorController,
	RoundSelectorController,
} from "views/components/round_selector/round_selector.controller";
import {
	type IQuestionsController,
	QuestionsController,
} from "views/pages/predictor/questions/questions.controller";
import {type IHeaderController, HeaderController} from "views/components/header/header.controller";
import {
	type IFavoriteTeamsStore,
	FavoriteTeamsStore,
} from "data/stores/favorite_teams/favorite_teams.store";
import {type IPredictionsStore, PredictionsStore} from "data/stores/predictions/predictions.store";
import {
	type IPredictionApiProvider,
	PredictionApiProvider,
} from "data/providers/api/prediction.api.provider";
import {
	type IQuestionController,
	QuestionController,
} from "views/pages/predictor/questions/question/question.controller";
import {
	type INumericalSliderController,
	NumericalSliderController,
} from "views/pages/predictor/questions/question/numerical_slider/numerical_slider.controller";
import {
	type IDropDownController,
	DropDownController,
} from "views/pages/predictor/questions/question/drop_down/drop_down.controller";
import {
	type IActionsController,
	ActionsController,
} from "views/components/game_bar/actions/actions.controller";
import {type IGameBarStore, GameBarStore} from "data/stores/game_bar/game_bar.store";
import {
	type IRankingApiProvider,
	RankingsApiProvider,
} from "data/providers/api/rankings.api.provider";
import {
	type IMultipleChoiceController,
	MultipleChoiceController,
} from "views/pages/predictor/questions/question/multiple_choice/multiple_choice.controller";
import {type IBannerStore, BannerStore} from "data/stores/banner/banner.store";
import {type IBannerController, BannerController} from "views/components/banner/banner.controller";
import {
	type IMyLeaguesSearchController,
	MyLeaguesSearchController,
} from "views/components/my_leagues_search/my_leagues_search.controller";
import {
	type IJoinLeaguesSearchController,
	JoinLeaguesSearchController,
} from "views/components/join_leagues_search/join_leagues_search.controller";
import {
	type ISnackbarController,
	SnackbarController,
} from "views/components/snackbar/snackbar.controller";
import {type ISnackbarStore, SnackbarStore} from "data/stores/snackbar/snackbar.store";
import {
	type ILeagueManageController,
	LeagueManageController,
} from "views/pages/league_manage/league_manage.controller";
import {
	type IModalLeagueInviteController,
	ModalLeagueInviteController,
} from "views/components/modals/modal_league_invite/modal_league_invite.controller";
import {type ITutorialStore, TutorialStore} from "data/stores/tutorial/tutorial.store";
import {
	type ITutorialController,
	TutorialController,
} from "views/components/tutorial/tutorial.controller";
import {
	type ITutorialStepController,
	TutorialStepController,
} from "views/components/tutorial/tutorial_step/tutorial_step.controller";
import {
	type IGameBarController,
	GameBarController,
} from "views/components/game_bar/game_bar.controller";
import {
	type IModalNextContestController,
	ModalNextContestController,
} from "views/components/modals/modal_next_contest/modal_next_contest.controller";
import {
	type IModalLeaguesController,
	ModalLeaguesController,
} from "views/components/modals/modal_leagues/modal_leagues.controller";
import {
	type IModalBoosterController,
	ModalBoosterController,
} from "views/components/modals/modal_booster/modal_booster.controller";
import {type ISliderStore, SliderStore} from "data/stores/slider/slider.store";
import {type IMenuStore, MenuStore} from "data/stores/menu/menu.store";
import {
	type ILeaderboardController,
	LeaderboardController,
} from "views/pages/leaderboard/leaderboard.controller";
import {type IRankingStore, RankingStore} from "data/stores/ranking/ranking.store";
import {
	LeaderboardComponentController,
	type ILeaderboardComponentController,
} from "views/components/leaderboard/leaderboard.controller";
import {
	type ISummaryViewController,
	SummaryViewController,
} from "views/pages/predictor/questions/summary_view/summary_view.controller";
import {
	type ISummaryViewCardController,
	SummaryViewCardController,
} from "views/pages/predictor/questions/summary_view/summary_view_card/summary_view_card.controller";
import {
	type ILeagueLadderController,
	LeagueLadderController,
} from "views/pages/league_ladder/league_ladder.controller";
import {
	type ILeagueRankingsStore,
	LeagueRankingsStore,
} from "data/stores/league_rankings/league_rankings.store";
import {
	type IMenuItemController,
	MenuItemController,
} from "views/components/menu/menu_item/menu_item.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IPredictionApiProvider>(Bindings.PredictionApiProvider).to(PredictionApiProvider);
	bind<IRankingApiProvider>(Bindings.RankingApiProvider).to(RankingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IContestsStore>(Bindings.ContestsStore).to(ContestsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IFavoriteTeamsStore>(Bindings.FavoriteTeamsStore)
		.to(FavoriteTeamsStore)
		.inSingletonScope();
	bind<IPredictionsStore>(Bindings.PredictionsStore).to(PredictionsStore).inSingletonScope();
	bind<IGameBarStore>(Bindings.GameBarStore).to(GameBarStore).inSingletonScope();
	bind<IBannerStore>(Bindings.BannerStore).to(BannerStore).inSingletonScope();
	bind<ISnackbarStore>(Bindings.SnackbarStore).to(SnackbarStore).inSingletonScope();
	bind<ITutorialStore>(Bindings.TutorialStore).to(TutorialStore).inSingletonScope();
	bind<IMenuStore>(Bindings.MenuStore).to(MenuStore).inSingletonScope();
	bind<IRankingStore>(Bindings.RankingStore).to(RankingStore).inSingletonScope();
	bind<ISliderStore>(Bindings.SliderStore).to(SliderStore).inSingletonScope();
	bind<ILeagueRankingsStore>(Bindings.LeagueRankingsStore)
		.to(LeagueRankingsStore)
		.inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<IModalRegisterController>(Bindings.ModalRegisterController).to(ModalRegisterController);
	bind<IPredictorController>(Bindings.PredictorController).to(PredictorController);
	bind<IRoundSelectorController>(Bindings.RoundSelectorController).to(RoundSelectorController);
	bind<IQuestionsController>(Bindings.QuestionsController).to(QuestionsController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IQuestionController>(Bindings.QuestionController).to(QuestionController);
	bind<INumericalSliderController>(Bindings.NumericalSliderController).to(
		NumericalSliderController
	);
	bind<IDropDownController>(Bindings.DropDownController).to(DropDownController);
	bind<IActionsController>(Bindings.ActionsController).to(ActionsController);
	bind<IMultipleChoiceController>(Bindings.MultipleChoiceController).to(MultipleChoiceController);
	bind<IBannerController>(Bindings.BannerController).to(BannerController);
	bind<IMyLeaguesSearchController>(Bindings.MyLeaguesSearchController).to(
		MyLeaguesSearchController
	);
	bind<IJoinLeaguesSearchController>(Bindings.JoinLeaguesSearchController).to(
		JoinLeaguesSearchController
	);
	bind<ISnackbarController>(Bindings.SnackbarController).to(SnackbarController);
	bind<ILeagueManageController>(Bindings.LeagueManageController).to(LeagueManageController);
	bind<IModalLeagueInviteController>(Bindings.ModalLeagueInviteController).to(
		ModalLeagueInviteController
	);
	bind<ITutorialController>(Bindings.TutorialController).to(TutorialController);
	bind<ITutorialStepController>(Bindings.TutorialStepController).to(TutorialStepController);
	bind<IGameBarController>(Bindings.GameBarController).to(GameBarController);
	bind<IModalNextContestController>(Bindings.ModalNextContestController).to(
		ModalNextContestController
	);
	bind<IModalLeaguesController>(Bindings.ModalLeaguesController).to(ModalLeaguesController);
	bind<IModalBoosterController>(Bindings.ModalBoosterController).to(ModalBoosterController);
	bind<ILeaderboardController>(Bindings.LeaderboardController).to(LeaderboardController);
	bind<ILeaderboardComponentController>(Bindings.LeaderboardComponentController).to(
		LeaderboardComponentController
	);
	bind<ISummaryViewController>(Bindings.SummaryViewController).to(SummaryViewController);
	bind<ISummaryViewCardController>(Bindings.SummaryViewCardController).to(
		SummaryViewCardController
	);
	bind<ILeagueLadderController>(Bindings.LeagueLadderController).to(LeagueLadderController);
	bind<IMenuItemController>(Bindings.MenuItemController).to(MenuItemController);
});
