import React, {Fragment} from "react";
import styled from "@emotion/styled";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {Box, Button, IconButton} from "@mui/material";
import {observer} from "mobx-react";
import {NavLink, useLocation} from "react-router-dom";
import {SSO_URL} from "data/constants";
import {useIsMobile} from "data/hooks/useIsMobile";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useIsMobileShare} from "data/hooks/useIsMobileShare";
import {Exist} from "views/components/exist/exist.component";
import {IHeaderController} from "./header.controller";
import {ReactComponent as Logo} from "assets/img/dailyPicksLogo.svg";
import heartOfGameLogo from "assets/img/heartOfGameBlueLogo.png";
import CloseIcon from "@mui/icons-material/Close";
import {DesktopMenuList} from "views/components/menu/menu.component";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {MenuItem, MLinkBox} from "views/components/menu/menu_item/menu_item.component";
import {PureButton} from "views/components/form/button.component";
import {ReactComponent as FacebookIcon} from "assets/img/icons/facebook.svg";
import {ReactComponent as XIcon} from "assets/img/icons/x.svg";
import {ReactComponent as ShareIcon} from "assets/img/icons/shareFilled.svg";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	padding: 8px 16px;
	background: ${({theme}) => theme.palette.header.bg};

	@media (min-width: 769px) {
		padding: 10px 20px;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	gap: 6px;

	@media (min-width: 400px) {
		gap: 12px;
	}

	@media (min-width: 769px) {
		gap: 0px;
	}
`;

const LogoWrapper = styled.div`
	@media (min-width: 769px) {
		svg {
			width: 160px;
			height: auto;
		}
	}
`;

const RightWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
	height: 40px;

	a {
		color: ${({theme}) => theme.palette.header.color};
		text-align: right;
		font-size: 16px;
		font-weight: 600;
		line-height: 150%; /* 24px */
	}

	@media (min-width: 769px) {
		gap: 16px;
	}
`;

const Img = styled.img`
	width: 56px;
	height: 24px;

	@media (min-width: 450px) {
		margin: 0 18px;
	}
`;

const NavBox = styled(Box)`
	display: flex;
	gap: 10px;

	& > div > a.active:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		border-bottom: 2px solid #000f9f;
	}

	a {
		padding: 0 16px;
		font-family: "IIHF 2024 Text", sans-serif;
		height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #6d6d6d;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 19.6px */
		position: relative;
		gap: 4px;

		svg path {
			fill: #6d6d6d;
		}

		&.active {
			color: #000f9f;
			font-weight: 600;

			svg path {
				fill: #000f9f;
			}
		}
	}
`;

const DesktopActions = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
`;

const MenuArea = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 14px 0;
	position: fixed;
	top: 58px;
	left: 0;
	height: 100%;
	width: 100%;
	transform: translateX(-100%);
	transition: all ease 0.5s;
	background: #fff;
	z-index: 19;

	&.open {
		transform: translateX(0);
	}

	> div {
		//height: 42px;
		border-bottom: 1px solid grey;
		display: flex;
		align-items: center;

		a,
		button {
			width: 100%;
			height: 42px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 12px 0 32px;

			div {
				height: 0;
			}

			&:hover + div {
				position: relative;
				height: auto;
				width: 100%;
				left: unset;

				a {
					border-bottom: 1px solid grey;
				}
			}
		}
	}
`;

const TutorialButton = styled(Button)`
	padding: 2px 8px;
	height: 42px;
	color: #000;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
	gap: 4px;
	text-transform: none;
`;

const ShareButton = styled(IconButton)`
	svg {
		fill: #000;
		width: 24px;
		height: 24px;
	}
`;

export const Header: React.FC = observer(() => {
	const {
		isAuthorized,
		isOpenMenu,
		toggleMenu,
		i18n,
		openTutorial,
		isShowTutorialButton,
		menu,
		shareViaMobile,
		shareViaTW,
		shareViaFB,
	} = useViewController<IHeaderController>(Bindings.HeaderController, {
		location: useLocation(),
	});
	const {isMobile} = useIsMobile();
	const {isMobileShare} = useIsMobileShare();

	return (
		<Fragment>
			<Wrapper>
				<Container>
					<Exist when={isMobile}>
						<IconButton onClick={toggleMenu}>
							{isOpenMenu ? <CloseIcon /> : <MenuRoundedIcon />}
						</IconButton>
						<MenuArea className={isOpenMenu ? "open" : "close"}>
							{menu.map((it) => (
								<MenuItem {...it} key={it.key} />
							))}

							<Exist when={isShowTutorialButton}>
								<MLinkBox>
									<PureButton onClick={openTutorial}>
										{i18n.t("nav.bar.tutorial", "Tutorial")}
									</PureButton>
								</MLinkBox>
							</Exist>
						</MenuArea>
					</Exist>

					<LogoWrapper>
						<Logo />
					</LogoWrapper>

					<RightWrapper>
						<Exist when={isMobile}>
							<Exist when={isMobileShare}>
								<ShareButton onClick={shareViaMobile}>
									<ShareIcon />
								</ShareButton>
							</Exist>

							<Exist when={!isMobileShare}>
								<ShareButton onClick={shareViaFB}>
									<FacebookIcon />
								</ShareButton>

								<ShareButton onClick={shareViaTW}>
									<XIcon />
								</ShareButton>
							</Exist>
						</Exist>

						<Exist when={!isMobile}>
							<Exist when={isAuthorized}>
								<NavLink to="/my-account">
									{i18n.t("header.account.my_account", "My Account")}
								</NavLink>
							</Exist>

							<Exist when={!isAuthorized}>
								<a href={SSO_URL}>
									{i18n.t("header.account.login_register", "Log in / Register")}
								</a>
							</Exist>
						</Exist>

						<Img src={heartOfGameLogo} alt="Heart of the game" />
					</RightWrapper>
				</Container>
			</Wrapper>

			<Exist when={!isMobile}>
				<Container>
					<NavBox>
						<DesktopMenuList menu={menu} />
					</NavBox>

					<DesktopActions>
						<Exist when={isShowTutorialButton}>
							<TutorialButton onClick={openTutorial}>
								{i18n.t("nav.bar.tutorial", "Tutorial")}
								<InfoRoundedIcon />
							</TutorialButton>
						</Exist>

						<ShareButton onClick={shareViaFB}>
							<FacebookIcon />
						</ShareButton>

						<ShareButton onClick={shareViaTW}>
							<XIcon />
						</ShareButton>
					</DesktopActions>
				</Container>
			</Exist>
		</Fragment>
	);
});
