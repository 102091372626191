import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
	IStaticContentPayload,
} from "data/stores/static_content/static_content.store";
import type {IPlayer} from "data/stores/players/players.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IContest} from "data/stores/contests/contests.store";
import type {ISquad} from "data/stores/squads/squads.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";
import type {IFavoriteTeams} from "data/stores/favorite_teams/favorite_teams.store";

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;
	favoriteTeams(): Promise<AxiosResponse<IFavoriteTeams[]>>;
	contests(): Promise<AxiosResponse<IContest[]>>;
	players(): Promise<AxiosResponse<IPlayer[]>>;
	squads(): Promise<AxiosResponse<ISquad[]>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;

	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(params: IStaticContentPayload): Promise<AxiosResponse<ICategories>>;
	helpSections(params: IStaticContentPayload): Promise<AxiosResponse<ISections>>;
	helpArticles(params: IStaticContentPayload): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	favoriteTeams = () => this._jsonClient.get<ICountry[]>("user/favourite_teams.json");
	contests = () => this._jsonClient.get<IContest[]>("predictor/contests.json");
	players = () => this._jsonClient.get<IPlayer[]>("players.json");
	squads = () => this._jsonClient.get<ISquad[]>("squads.json");
	checksums = () => this._jsonClient.get<IChecksums>("checksums.json");
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`predictor/loco/${locale}.json`);

	helpCategories = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`, params);
	helpSections = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`, params);
	helpArticles = ({locale, ...params}: IStaticContentPayload) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`, params);
}
