import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";

interface IProps {
	leagueId: number;
}

export interface ILeagueInviteController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
}

@injectable()
export class LeagueInviteController implements ILeagueInviteController {
	@observable private _leagueId?: number;

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	@action onChange({leagueId}: IProps) {
		this._leagueId = leagueId;
	}

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;
	}
}
