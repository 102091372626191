import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IJSONProvider} from "data/providers/json/json.provider";

export interface IFavoriteTeams {
	name: string;
	code: string;
}

export interface IFavoriteTeamsStore {
	fetchFavoriteTeams(): Promise<void>;
	list: IFavoriteTeams[];
}

@injectable()
export class FavoriteTeamsStore implements IFavoriteTeamsStore {
	@observable private _list: IFavoriteTeams[] = [];

	get list() {
		return this._list;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@action async fetchFavoriteTeams() {
		const {data} = await this._jsonProvider.favoriteTeams();

		runInAction(() => {
			this._list = data;
		});
	}
}
