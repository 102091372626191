import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {first, get, isArray, last} from "lodash";
import {ChangeEvent, FocusEvent, type MutableRefObject} from "react";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore, IQuestion} from "data/stores/contests/contests.store";
import type {IPrediction, IPredictionsStore} from "data/stores/predictions/predictions.store";
import {QuestionStatus} from "data/enums";

interface IProps {
	id: number;
	inputRef: MutableRefObject<HTMLInputElement | null>;
}

export interface INumericalSliderController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get question(): IQuestion | undefined;
	get prediction(): IPrediction | undefined;
	get answerCN(): string;
	get min(): number;
	get max(): number;
	get value(): string | undefined;
	get isDisabled(): boolean;
	get className(): string;
	get isLock(): boolean;
	get isComplete(): boolean;
	get isCorrect(): boolean;
	get points(): number;
	get inputRef(): MutableRefObject<HTMLInputElement | null> | undefined;

	onChangeHandler: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onBlurHandler: (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	setPredictionAnswer: (value: number | readonly number[]) => void;
}

@injectable()
export class NumericalSliderController implements INumericalSliderController {
	@observable private _id: number = 0;
	@observable private _inputRef?: MutableRefObject<HTMLInputElement | null>;

	get inputRef() {
		return this._inputRef;
	}

	get question() {
		return this._contestsStore.getQuestionById(this._id);
	}

	get options() {
		return get(this.question, "options", []);
	}

	get prediction() {
		return this._predictionsStore.getPredictionByQuestionId(this._id);
	}

	get hasPrediction() {
		return Boolean(this.prediction?.optionId);
	}

	get isCorrect() {
		return this.prediction?.isCorrect || false;
	}

	get answerCN() {
		return this.hasPrediction ? "answered" : "";
	}

	get min() {
		return Number(first(this.options)?.value || 0);
	}

	get max() {
		return Number(last(this.options)?.value || 100);
	}

	get value() {
		return this.options.find(({id}) => id === this.prediction?.optionId)?.value;
	}

	get isDisabled() {
		return this.question?.status !== QuestionStatus.Open;
	}

	get isBoosted() {
		return Boolean(this.prediction?.isBoosted);
	}

	get className() {
		return this.isBoosted ? "boosted" : "";
	}

	get isOpen() {
		return this.question?.status === QuestionStatus.Open;
	}

	get isLock() {
		return this.question?.status === QuestionStatus.Locked;
	}

	get isComplete() {
		return this.question?.status === QuestionStatus.Complete;
	}

	get points() {
		return this.prediction?.points || 0;
	}

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._id = param.id;
		this._inputRef = param.inputRef;
	}

	onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = Number(e.target.value);

		if (!isFinite(value)) {
			return;
		}

		this.setPredictionAnswer(value);
	};

	onBlurHandler = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		let value = Number(e.target.value);

		if (e.target.value === "") {
			return;
		}

		if (value < this.min) {
			value = this.min;
		}

		if (value > this.max) {
			value = this.max;
		}

		this.setPredictionAnswer(value);
		e.target.value = `${value}`;
	};

	setPredictionAnswer = (value: number | readonly number[]) => {
		if (!this.question || isArray(value)) {
			return;
		}

		const optionId = this.options.find((option) => +option.value === value)?.id;

		if (!optionId) {
			return;
		}

		this._predictionsStore.setPredictionAnswer(this.question.id, optionId);

		const inputRef = this._inputRef?.current;

		if (inputRef) {
			inputRef.value = value.toString();
		}
	};
}
