import React from "react";
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {ReactComponent as Logo} from "assets/img/dailyPicksLogo.svg";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
	color: ${({theme}) => theme.palette.footer.color};

	@media (min-width: 769px) {
		padding: 20px;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;

	@media (min-width: 769px) {
		gap: 16px;
	}
`;

const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	@media (min-width: 769px) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		svg {
			width: 160px;
			height: auto;
		}
	}
`;

const BottomContainer = styled.div`
	text-align: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 135%;

	a {
		color: ${({theme}) => theme.palette.footer.color};
	}

	@media (min-width: 769px) {
		text-align: left;
	}
`;

const Links = styled.div`
	display: flex;
	gap: 8px;
	text-align: right;
	font-size: 16px;
	font-weight: 600;
	line-height: 150%;
`;

const Link = styled(NavLink)`
	text-decoration-line: underline;
`;

export const Footer: React.FC = () => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return (
		<Wrapper>
			<Container>
				<TopContainer>
					<Logo />

					<Links>
						<Link to="/help/t&cs">
							{i18n.t("footer.link.terms", "Terms & Conditions")}
						</Link>

						<div>|</div>

						<Link to="/help/contact-us">
							{i18n.t("footer.link.contact_us", "Contact Us")}
						</Link>
					</Links>
				</TopContainer>

				<BottomContainer>
					<a
						href="https://geniussports.com/media-engagement/"
						rel="noreferrer"
						target="_blank">
						{i18n.t("footer.link.powered_by", "© 2024 Genius Sports")}
					</a>
				</BottomContainer>
			</Container>
		</Wrapper>
	);
};
