import {NavLink} from "react-router-dom";
import {Box, IconButton} from "@mui/material";
import React from "react";
import {IMenuItem} from "data/constants/menu";
import styled from "@emotion/styled";
import {ReactComponent as MenuArrowIcon} from "assets/img/icons/menu_arrow_up.svg";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

export const LinkBox = styled(Box)`
	position: relative;

	&.sub_link {
		&:hover div {
			display: flex;
			height: auto;
		}

		&:hover > a button {
			transform: rotate(0deg);
		}

		> a {
			button {
				transform: rotate(180deg);
				transition: all ease 0.3s;
			}
		}
	}
`;

export const SubLinkBox = styled(Box)`
	position: absolute;
	left: 0;
	min-width: 200px;
	height: 0;
	background: #fff;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: all ease 0.5s;
	z-index: 1;

	a {
		height: 42px;
		z-index: 19;
		display: flex;
		justify-content: flex-start;

		&:hover {
			background: #7dcbef;
			transition: all ease 0.3s;
		}
	}
`;

interface IProps {
	menu: IMenuItem[];
	onClick?: () => void;
}

const Button = styled(IconButton)`
	padding: 0;
`;

export const DesktopMenuList: React.FC<IProps> = ({menu}) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return menu
		.filter(({isMobileOnly}) => !isMobileOnly)
		.map((it, index) => (
			<LinkBox key={index} className={it.subMenu?.length ? "sub_link" : ""}>
				<NavLink to={it.path}>
					{i18n.t(it.key, it.name)}
					{it.subMenu && (
						<Button>
							<MenuArrowIcon />
						</Button>
					)}
				</NavLink>
				{it.subMenu ? (
					<SubLinkBox>
						{it.subMenu.map((el, idx) => (
							<NavLink key={idx} to={el.path}>
								{i18n.t(el.key, el.name)}
							</NavLink>
						))}
					</SubLinkBox>
				) : null}
			</LinkBox>
		));
};
