import React from "react";
import {observer} from "mobx-react";
import {Button, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as ErrorIcon} from "assets/img/icons/error.svg";
import {Exist} from "views/components/exist/exist.component";
import {
	ModalBase,
	ModalContent,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import type {IModalConfirmController} from "./modal_confirm.controller";

const Icon = styled.div`
	width: 52px;
	margin-bottom: 16px;

	svg {
		width: 100%;
		height: auto;
	}

	@media (min-width: 769px) {
		width: 64px;
	}
`;

const Title = styled(ModalTitle)`
	color: ${({theme}) => theme.palette.modal.secondColor};
	margin-bottom: 8px;
`;

const Desc = styled(ModalDesc)`
	margin-bottom: 16px;
`;

const SecondaryButton = styled(Button)`
	color: #e40000;
`;

export const ModalConfirm: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalConfirmController>(
		Bindings.ModalConfirmController
	);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close}>
			<ModalContent>
				<Icon>
					<ErrorIcon />
				</Icon>

				<Title>{modalContent?.title ?? i18n.t("modal.confirm.title", "Confirm")}</Title>
				<Exist when={modalContent?.message}>
					<Desc
						/*Disabled due html come from our Loco and must be safety*/
						/*eslint-disable-next-line*/
						dangerouslySetInnerHTML={{
							__html: modalContent?.message || "",
						}}
					/>
				</Exist>

				<Stack direction="row" gap={1.5} sx={{width: "100%"}}>
					<Button fullWidth variant="contained" onClick={close}>
						{modalContent?.cancelBtnText ??
							i18n.t("modal.confirm.cancel_button", "Cancel")}
					</Button>

					<Exist when={modalContent?.callback}>
						<SecondaryButton
							fullWidth
							color="error"
							variant="contained"
							onClick={modalContent?.callback}>
							{modalContent?.confirmBtnText ??
								i18n.t("modal.confirm.confirm_button", "Confirm")}
						</SecondaryButton>
					</Exist>
				</Stack>
			</ModalContent>
		</ModalBase>
	);
});
