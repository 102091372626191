import {action, computed, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContest, IContestsStore} from "data/stores/contests/contests.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ChangeEvent} from "react";
import type {IUpdateLeaguePayload} from "data/providers/api/leagues.api.provider";
import type {IApiResponse} from "data/services/http";
import type {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {debounce, identity, isEqual, partial, values} from "lodash";
import {ContestStatus, LeaguePrivacy, LeagueStatus, ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";

interface IProps {
	leagueId: number;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	leagueName: string;
	startId: number;
	privacy: LeaguePrivacy;
}

const SAVED_BUTTON_TIME = 5000;

export interface ILeagueSettingsController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get contests(): IContest[];
	get isLoading(): boolean;
	get isLeaguePresenceRequestLoading(): boolean;
	get isUpdateButtonDisabled(): boolean;
	get isLeagueNameFieldDisabled(): boolean;
	get isLeagueStarted(): boolean;
	get isFormDisabled(): boolean;
	get formValue(): IFormValue;
	get isShowUpdatedBtn(): boolean;
	get error(): Record<string, string> | null;

	handleUpdateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	startRoundChange: (event: ChangeEvent<HTMLInputElement>) => void;
	leaveLeague: () => void;
	checkLeagueNameHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LeagueSettingsController implements ILeagueSettingsController {
	@observable private _leagueId?: number;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;
	@observable private _isShowUpdatedBtn = false;
	@observable private _timerId?: ReturnType<typeof setTimeout>;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	@observable private _requestsStates = {
		general: RequestState.PENDING,
		leaguePresence: RequestState.IDLE,
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
	};

	@observable private _formValue: IFormValue = {
		leagueName: "",
		startId: 0,
		privacy: LeaguePrivacy.PRIVATE,
	};

	get isShowUpdatedBtn() {
		return this._isShowUpdatedBtn;
	}

	private get leagueID() {
		return this._leagueId!;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	@computed get contests() {
		return this._contestsStore.list.filter((contest) => {
			const isScheduled = isEqual(contest.status, ContestStatus.Open);
			const isLeagueStartRound = isEqual(this.league?.startContestId, contest.id);

			return isScheduled || isLeagueStartRound;
		});
	}

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return isEqual(this._requestsStates.general, RequestState.PENDING);
	}

	get isLeaguePresenceRequestLoading() {
		return isEqual(this._requestsStates.leaguePresence, RequestState.PENDING);
	}

	get isFormDisabled() {
		return this.isLoading || this.isLeagueStarted;
	}

	get isLeagueNameFieldDisabled() {
		return this.isLoading;
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	@computed
	private get hasChanges() {
		return !isEqual(
			{
				name: this.league?.name,
				privacy: this.league?.privacy,
				startContestId: this.league?.startContestId,
			},
			{
				name: this.formValue.leagueName,
				privacy: this.formValue.privacy,
				startContestId: this.formValue.startId,
			}
		);
	}

	get isUpdateButtonDisabled() {
		if (!this.hasChanges || this._isShowUpdatedBtn) {
			return true;
		}

		if (this.isLeagueStarted) {
			return !this.isFormValid;
		}

		return this.isFormDisabled || !this.isFormValid;
	}

	@computed
	private get isFormValid() {
		return values(this._formValue).every(identity);
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;
	}

	private clearError() {
		this._errorMsg = null;
		this._errorPlace = "";
	}

	checkLeagueNameHandler = debounce(async (e: ChangeEvent<HTMLInputElement>) => {
		try {
			const name = e.target.value;

			if (this.league?.name !== name) {
				await this._leaguesStore.checkLeagueName(name);
			}

			this.clearError();
		} catch (e) {
			this.reportError(extractErrorMessage(e as AxiosError<IApiResponse>), "leagueName");
		}
	}, 300);

	private hideUpdatedBtn = () => {
		clearTimeout(this._timerId);
		this._isShowUpdatedBtn = false;
	};

	@action private createSuccessHandlerOf = (type: keyof typeof this._requestsStates) => {
		this._requestsStates[type] = RequestState.SUCCESS;
	};

	@action private createErrorHandlerOf = (
		type: keyof typeof this._requestsStates,
		error: AxiosError<IApiResponse>
	) => {
		this._requestsStates[type] = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private setPendingState = (type: keyof typeof this._requestsStates) => {
		this._requestsStates[type] = RequestState.PENDING;
	};

	handleUpdateLeague = () => {
		this.setPendingState("general");

		const {leagueName, startId, privacy} = this._formValue;

		const payload: IUpdateLeaguePayload = {
			name: leagueName,
			leagueId: this.leagueID,
			privacy,
		};

		if (!this.isLeagueStarted) {
			payload.startContestId = startId;
		}

		this._leaguesStore
			.updateLeague(payload)
			.then(partial(this.createSuccessHandlerOf, "general"))
			.then(() => {
				runInAction(() => {
					this._isShowUpdatedBtn = true;

					this._timerId = setTimeout(() => {
						runInAction(() => {
							this._isShowUpdatedBtn = false;
						});
					}, SAVED_BUTTON_TIME);
				});
			})
			.catch(partial(this.createErrorHandlerOf, "general"));
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;

		this.hideUpdatedBtn();
		this.clearError();
	};

	@action startRoundChange = (event: ChangeEvent<HTMLInputElement>) => {
		this._formValue.startId = Number(event.target.value);

		this.hideUpdatedBtn();
		this.clearError();
	};

	@action leaveLeague = () => {
		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t(
				"league.leave.confirm",
				"Do you really want to leave this league?"
			),
			callback: () => {
				this._modalsStore.hideModal();
				this.setPendingState("leaguePresence");

				this._leaguesStore
					.leaveLeague({leagueId: this.leagueID})
					.then(() => this._leaguesStore.fetchLeagueUsers({leagueId: this.leagueID}))
					.then(partial(this.createSuccessHandlerOf, "leaguePresence"))
					.catch(partial(this.createErrorHandlerOf, "leaguePresence"));
			},
		});
	};

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;

		Promise.all([
			this._contestsStore.fetchContests(),
			this._leaguesStore.fetchLeagueUsers({leagueId}),
		])
			.then(partial(this.createSuccessHandlerOf, "general"))
			.catch(partial(this.createErrorHandlerOf, "general"));

		this._leagueDisposer = reaction(
			() => this.league,
			() => {
				if (!this.league) return;

				const {startContestId, name, privacy} = this.league;

				this._formValue.startId = startContestId;
				this._formValue.leagueName = name;
				this._formValue.privacy = privacy;
			},
			{fireImmediately: true}
		);
	}

	dispose() {
		this._leagueDisposer?.();
	}
}
