import React from "react";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {Button} from "@mui/material";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {LeaguePrivacy} from "data/enums";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {Exist} from "views/components/exist/exist.component";
import {IModalLeagueInviteController} from "./modal_league_invite.controller";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 769px) {
		max-width: 360px;
	}
`;

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 8px;
	color: ${({theme}) => theme.palette.modal.secondColor};

	@media (min-width: 769px) {
		font-size: 24px;
	}
`;

const SModalDesc = styled(ModalDesc)`
	margin-bottom: 12px;
	font-weight: 600;

	@media (min-width: 769px) {
		font-size: 16px;
		margin-bottom: 16px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	border-radius: 4px;
	background: var(--Neutrals-10, #f6f6f6);
	margin-bottom: 12px;
	padding: 12px;
	width: 100%;

	@media (min-width: 769px) {
		margin-bottom: 16px;
	}
`;

const ContentTitle = styled.div`
	font-family: var(--fontFamilySecond);
	font-size: 16px;
	line-height: 100%;
	text-transform: uppercase;

	@media (min-width: 769px) {
		font-size: 20px;
	}
`;

const ContentItem = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 140%;

	@media (min-width: 769px) {
		font-size: 16px;
	}
`;

const ContentItemTitle = styled(ContentItem)`
	font-weight: 600;
`;

export const ModalLeagueInvite: React.FC = observer(() => {
	const {isOpen, i18n, close, league, joinLeague} =
		useViewController<IModalLeagueInviteController>(Bindings.ModalLeagueInviteController);

	if (!league) {
		return null;
	}

	return (
		<ModalBase isOpen={isOpen} closeHandler={close}>
			<Container>
				<SModalTitle>
					{i18n.t(
						"invite_modal.header.copy",
						"Congrats, You’ve Been Invited To Join A League!"
					)}
				</SModalTitle>

				<SModalDesc>{league.name}</SModalDesc>

				<Content>
					<ContentTitle>
						{i18n.t("invite_modal.league_details.header", "League Details")}
					</ContentTitle>

					<ContentItem>
						<ContentItemTitle>
							{i18n.t("invite_modal.league_details.chairman", "Chairman")}
						</ContentItemTitle>

						<div>{league.leagueManager?.displayName}</div>
					</ContentItem>

					<ContentItem>
						<ContentItemTitle>
							{i18n.t("invite_modal.league_details.round_start", "Starting Day")}
						</ContentItemTitle>

						<div>{league.startContestName}</div>
					</ContentItem>

					<ContentItem>
						<ContentItemTitle>
							{i18n.t("invite_modal.league_details.privacy", "Privacy Settings")}
						</ContentItemTitle>

						<div>
							<Exist when={league.privacy === LeaguePrivacy.PUBLIC}>
								{i18n.t("league_card.label_type.public", "Public")}
							</Exist>

							<Exist when={league.privacy === LeaguePrivacy.PRIVATE}>
								{i18n.t("league_card.label_type.private", "Private")}
							</Exist>
						</div>
					</ContentItem>
				</Content>

				<Button variant="contained" onClick={joinLeague} fullWidth>
					{i18n.t("invite_modal.join_league.button", "join now")}
				</Button>
			</Container>
		</ModalBase>
	);
});
