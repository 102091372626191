import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {NavLink} from "react-router-dom";
import {Collapse, IconButton} from "@mui/material";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IMenuItemController} from "./menu_item.controller";
import {IMenuItem} from "data/constants/menu";
import {LinkBox, SubLinkBox} from "views/components/menu/menu.component";
import styled from "@emotion/styled";
import KeyboardArrowUpRounded from "@mui/icons-material/KeyboardArrowUpRounded";

export const MSubLinkBox = styled(SubLinkBox)`
	position: relative;
	left: unset;
	height: auto;
	background: #fff;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: all ease 0.5s;
	z-index: 1;

	> a {
		height: 42px !important;
		z-index: 19;
		display: flex;
		justify-content: flex-start;
		border-bottom: 1px solid grey;
		padding: 0 5px 0 48px !important;

		color: var(--Text-Dark, #000);
		font-family: var(--fontFamilyBase);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;

		&:last-child {
			border-bottom: none;
		}

		&.active,
		&:hover {
			background: #7dcbef;
			transition: all ease 0.3s;
		}
	}
`;

export const MLinkBox = styled(LinkBox)`
	a,
	button {
		color: #000;
		font-family: var(--fontFamilyBase);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 19.6px */
		transition: all ease 0.3s;

		&.active,
		&:hover {
			background: #000f9f;
			color: #fff;

			svg path {
				fill: #ffffff;
			}
		}
	}
`;

const ArrowButton = styled(IconButton)`
	&& {
		transform: rotate(180deg);
		width: 40px;
		height: 40px;
		padding: 8px;

		&.open {
			transform: rotate(0deg);
		}
	}
`;

export const MenuItem: React.FC<IMenuItem> = observer((item) => {
	const {i18n, toggleMenu, isOpen, toggleList, className} =
		useViewController<IMenuItemController>(Bindings.MenuItemController);

	return (
		<Fragment>
			<MLinkBox className={item.subMenu?.length ? "sub_link" : ""}>
				<NavLink to={item.path} onClick={toggleMenu}>
					{i18n.t(item.key, item.name)}

					{item.subMenu && (
						<ArrowButton onClick={toggleList} className={className}>
							<KeyboardArrowUpRounded />
						</ArrowButton>
					)}
				</NavLink>
			</MLinkBox>

			<Collapse in={isOpen}>
				{item.subMenu ? (
					<MSubLinkBox>
						{item.subMenu.map((el, idx) => (
							<NavLink key={idx} to={el.path} onClick={toggleMenu}>
								{i18n.t(el.key, el.name)}
							</NavLink>
						))}
					</MSubLinkBox>
				) : null}
			</Collapse>
		</Fragment>
	);
});
