import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {LeaguePrivacy} from "data/enums";
import type {ILeague} from "data/stores/leagues/leagues.store";

export interface ICreateLeaguePayload {
	name: string;
	startContestId: number; // Start round (must exist and be with the SCHEDULED status)
	privacy: LeaguePrivacy;
}

export interface ILeagueIdPayload {
	leagueId: number;
}

interface IPaginationPayload {
	page?: number;
	limit?: number;
}

export type IUpdateLeaguePayload = Partial<ICreateLeaguePayload> & ILeagueIdPayload;

export interface ILeaguesForJoinPayload extends IPaginationPayload {
	search?: string;
}

export interface ILeagueInvitesPayload extends ILeagueIdPayload {
	invites: {email: string}[];
}

export interface IRemoveUserFromLeaguePayload extends ILeagueIdPayload {
	userId: number;
}

export interface ILeagueCodePayload {
	code: string;
}

export type ILeagueUsersPayload = ILeagueIdPayload & IPaginationPayload;

export interface ILeaguesPayload extends IPaginationPayload {
	privacy?: LeaguePrivacy;
}

export interface ILeagueManager {
	userId: number | null;
	displayName: string | null;
}

export interface ILeagueUser {
	userId: number;
	displayName: string;
}

type ILeagueResponse = IApiResponse<{league: ILeague}>;
type ILeaguesListResponse = IApiResponse<{leagues: ILeague[]; nextPage: boolean}>;
type ILeagueUserListResponse = IApiResponse<{users: ILeagueUser[]; nextPage: boolean}>;

export interface ILeaguesApiProvider {
	createLeague: (params: ICreateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	checkLeagueName: (name: string) => Promise<AxiosResponse<void>>;
	updateLeague: (params: IUpdateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeaguesForJoin: (
		params: ILeaguesForJoinPayload
	) => Promise<AxiosResponse<ILeaguesListResponse>>;
	leaveLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	inviteUsersToLeague: (params: ILeagueInvitesPayload) => Promise<AxiosResponse<void>>;
	removeUserFromLeague: (params: IRemoveUserFromLeaguePayload) => Promise<AxiosResponse<void>>;
	joinToLeague: (params: ILeagueCodePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeagueUsers: (
		params: ILeagueUsersPayload
	) => Promise<AxiosResponse<ILeagueUserListResponse>>;
	fetchMyLeagues: (params: ILeaguesPayload) => Promise<AxiosResponse<ILeaguesListResponse>>;
	fetchLeagueByCode: (params: ILeagueCodePayload) => Promise<AxiosResponse<ILeagueResponse>>;
}

@injectable()
export class LeaguesApiProvider implements ILeaguesApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	createLeague = (params: ICreateLeaguePayload) =>
		this._http.post<ILeagueResponse>("predictor/league", params);

	checkLeagueName = (name: string) =>
		this._http.post<void>("predictor/league/check-name-uniquness", {name});

	updateLeague = ({leagueId, ...params}: IUpdateLeaguePayload) =>
		this._http.post<ILeagueResponse>(`predictor/league/${leagueId}`, params);

	fetchLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.get<ILeagueResponse>(`predictor/league/show-by-id/${leagueId}`);

	fetchLeaguesForJoin = (params: ILeaguesForJoinPayload) =>
		this._http.get<ILeaguesListResponse>("predictor/leagues/not-joined", params);

	leaveLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.post<void>(`predictor/league/${leagueId}/leave`);

	inviteUsersToLeague = ({leagueId, ...params}: ILeagueInvitesPayload) =>
		this._http.post<void>(`predictor/league/${leagueId}/invite`, params);

	removeUserFromLeague = ({leagueId, userId}: IRemoveUserFromLeaguePayload) =>
		this._http.post<void>(`predictor/league/${leagueId}/delete-join/${userId}`);

	joinToLeague = ({code}: ILeagueCodePayload) =>
		this._http.post<ILeagueResponse>(`predictor/league/join-by-code/${code}`);

	fetchLeagueUsers = ({leagueId, ...params}: ILeagueUsersPayload) =>
		this._http.get<ILeagueUserListResponse>(
			`predictor/league/${leagueId}/league-users`,
			params
		);

	fetchMyLeagues = (params: ILeaguesPayload) =>
		this._http.get<ILeaguesListResponse>("predictor/leagues", params);

	fetchLeagueByCode = ({code}: ILeagueCodePayload) =>
		this._http.get<ILeagueResponse>(`predictor/league/show-by-code/${code}`);
}
