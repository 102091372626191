import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface IMenuStore {
	get isOpen(): boolean;

	toggleMenu(): void;
	closeMenu(): void;
}

@injectable()
export class MenuStore implements IMenuStore {
	@observable private _isOpen: boolean = false;

	get isOpen() {
		return this._isOpen;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@action toggleMenu() {
		this._isOpen = !this._isOpen;
	}

	@action closeMenu() {
		this._isOpen = false;
	}
}
