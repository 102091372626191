import {makeAutoObservable, observable, runInAction} from "mobx";
import {AxiosError} from "axios";
import {inject, injectable} from "inversify";
import {isNumber} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";

export interface IModalBoosterController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get isDisabled(): boolean;

	close: () => void;
	boost: () => void;
}

@injectable()
export class ModalBoosterController implements IModalBoosterController {
	@observable private _isLoading = false;

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.BOOSTER;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get isDisabled() {
		return this._isLoading;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	private showNextContestModal = (availableContestId: number) => {
		this._modalsStore.showModal(ModalType.NEXT_CONTEST, {
			contestId: this.modalContent?.contestId,
			availableContestId,
		});
	};

	private showLeaguesModal = () => {
		this._modalsStore.showModal(ModalType.LEAGUES, {
			contestId: this.modalContent?.contestId,
		});
	};

	boost = async () => {
		try {
			this._isLoading = true;

			const availableContestId = await this._predictionsStore.boostRandomQuestion();

			if (isNumber(availableContestId)) {
				this.showNextContestModal(availableContestId);
			} else {
				this.showLeaguesModal();
			}
		} catch (e) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	};
}
