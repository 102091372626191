import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ISnackbarStore} from "data/stores/snackbar/snackbar.store";
import {SnackbarOrigin} from "@mui/material/Snackbar/Snackbar";

interface IProps {
	isMobile: boolean;
}

export interface ISnackbarController extends ViewController<IProps> {
	get isOpen(): boolean;
	get message(): string;
	get anchorOrigin(): SnackbarOrigin;

	handleClose: () => void;
}

@injectable()
export class SnackbarController implements ISnackbarController {
	@observable private _isMobile: boolean = false;

	get isOpen() {
		return this._snackbarStore.isOpen;
	}

	get message() {
		return this._snackbarStore.message;
	}

	get anchorOrigin(): SnackbarOrigin {
		if (this._isMobile) {
			return {
				vertical: "top",
				horizontal: "center",
			};
		}

		return {
			vertical: "bottom",
			horizontal: "right",
		};
	}

	constructor(@inject(Bindings.SnackbarStore) private _snackbarStore: ISnackbarStore) {
		makeAutoObservable(this);
	}

	init(params: IProps) {
		this.onChange(params);
	}

	onChange({isMobile}: IProps) {
		this._isMobile = isMobile;
	}

	handleClose = () => {
		this._snackbarStore.handleClose();
	};
}
