import React from "react";
import {observer} from "mobx-react";
import MuiSnackbar from "@mui/material/Snackbar";
import styled from "@emotion/styled";
import {Alert, IconButton} from "@mui/material";
import {MOBILE_HEADER_HEIGHT} from "data/constants";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useIsMobile} from "data/hooks/useIsMobile";
import {ISnackbarController} from "./snackbar.controller";
import {ReactComponent as CloseIcon} from "assets/img/icons/snackbarClose.svg";

const SAlert = styled(Alert)`
	color: #ffffff;
	background: #008805;
	font-size: 14px;
	line-height: 140%;
	width: 100%;

	.MuiAlert-action {
		padding-top: 0;
	}

	b {
		font-weight: 600;
	}
`;

const SSnackbar = styled(MuiSnackbar)`
	@media (max-width: 768px) {
		top: ${MOBILE_HEADER_HEIGHT}px;
	}
`;

export const Snackbar: React.FC = observer(() => {
	const {isOpen, handleClose, message, anchorOrigin} = useViewController<ISnackbarController>(
		Bindings.SnackbarController,
		{
			isMobile: useIsMobile().isMobile,
		}
	);

	return (
		<SSnackbar
			open={isOpen}
			anchorOrigin={anchorOrigin}
			autoHideDuration={5000}
			onClose={handleClose}>
			<SAlert
				onClose={handleClose}
				icon={false}
				variant="filled"
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				}>
				<div
					/*Disabled due html come from our Loco and must be safety*/
					/*eslint-disable-next-line*/
					dangerouslySetInnerHTML={{
						__html: message,
					}}
				/>
			</SAlert>
		</SSnackbar>
	);
});
