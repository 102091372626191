import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, reaction} from "mobx";
import {MutableRefObject, useRef} from "react";
import Slider from "react-slick";
import {isNumber} from "lodash";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {type ViewController} from "data/types/structure";

export interface ITutorialController extends ViewController {
	sliderRef: MutableRefObject<Slider | null>;

	get isOpen(): boolean;
	get activeStep(): number;

	setActiveStep: (step: number) => void;
	close: () => void;
}

@injectable()
export class TutorialController implements ITutorialController {
	@observable public sliderRef = useRef<Slider>(null);
	@observable private _disposer?: ReturnType<typeof reaction>;

	get isOpen() {
		return this._tutorialStore.isOpen;
	}

	get activeStep() {
		return this._tutorialStore.activeStep;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	init() {
		this._disposer = reaction(
			() => this.activeStep,
			(sliderIndex) => {
				if (isNumber(sliderIndex)) {
					this.sliderRef.current?.slickGoTo(sliderIndex);
				}
			}
		);
	}

	setActiveStep = (step: number) => {
		this._tutorialStore.setActiveStep(step);
	};

	close = () => {
		this._tutorialStore.setIsOpen(false);
	};

	dispose() {
		this._disposer?.();
	}
}
