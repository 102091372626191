import React from "react";
import {observer} from "mobx-react";
import {Button, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {
	ModalBase,
	ModalContent,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {IModalBoosterController} from "./modal_booster.controller";
import {ReactComponent as PuckIcon} from "assets/img/icons/puck.svg";

const Icon = styled.div`
	margin-bottom: 16px;

	svg {
		width: 100%;
		height: auto;
	}
`;

const Title = styled(ModalTitle)`
	color: ${({theme}) => theme.palette.modal.color};
	margin-bottom: 8px;
`;

const Desc = styled(ModalDesc)`
	margin-bottom: 16px;
`;

const SecondaryButton = styled(Button)`
	border-width: 2px;
	white-space: nowrap;
	gap: 4px;

	svg {
		width: 24px;
		min-width: 24px;
		height: 24px;
	}
`;

export const ModalBooster: React.FC = observer(() => {
	const {isOpen, i18n, close, boost, isDisabled} = useViewController<IModalBoosterController>(
		Bindings.ModalBoosterController
	);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close}>
			<ModalContent className="full-width">
				<Icon>
					<PuckIcon />
				</Icon>

				<Title>{i18n.t("booster.modal.heading", "Don't forget your booster!")}</Title>

				<Desc>
					{i18n.t(
						"booster.modal.body",
						"Pick a question to boost your points and earn double the points for a correct answer!"
					)}
				</Desc>

				<Stack sx={{flexDirection: {xs: "column", md: "row"}, width: "100%"}} gap={1.5}>
					<Button fullWidth variant="contained" onClick={close}>
						{i18n.t("booster.modal.return", "pick a question")}
					</Button>

					<SecondaryButton
						fullWidth
						variant="contained"
						color="secondary"
						onClick={boost}
						disabled={isDisabled}>
						{i18n.t("booster.modal.random_boost", "Boost a random question")}

						<PuckIcon />
					</SecondaryButton>
				</Stack>
			</ModalContent>
		</ModalBase>
	);
});
