import {makeAutoObservable, observable, reaction} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {first} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {IContestsStore} from "data/stores/contests/contests.store";
import {IApiResponse} from "data/services/http";
import {ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {type IModalsStore} from "data/stores/modals/modals.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IGameBarStore} from "data/stores/game_bar/game_bar.store";
import type {IBannerStore} from "data/stores/banner/banner.store";

export interface IPredictorController extends ViewController {
	get isLoading(): boolean;
	get isPredictionLoading(): boolean;
}

@injectable()
export class PredictorController implements IPredictorController {
	@observable private _requestState = RequestState.PENDING;
	@observable private _predictionRequestState = RequestState.PENDING;
	@observable _fetchPredictionDisposer?: ReturnType<typeof reaction>;
	@observable private _hasCheckContestPredictions = false;

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isPredictionLoading() {
		return this._predictionRequestState === RequestState.PENDING;
	}

	get contestId() {
		return this._contestsStore.selectedContest?.id;
	}

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: IGameBarStore,
		@inject(Bindings.BannerStore) private _bannerStore: IBannerStore
	) {
		makeAutoObservable(this);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	private onFetchPredictionsError = (error: AxiosError<IApiResponse>) => {
		this._predictionRequestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private checkContestPredictions = () => {
		if (this._hasCheckContestPredictions) return;

		const nextOpenContestId = first(
			this._contestsStore.scheduleContests.filter(
				({id}) => id !== this._contestsStore.currentContest?.id
			)
		)?.id;

		if (this._predictionsStore.hasAllPredictions && nextOpenContestId) {
			this._contestsStore.setSelectedContestId(nextOpenContestId);
		}

		this._hasCheckContestPredictions = true;
	};

	private onFetchPredictionsSuccess = () => {
		this._predictionRequestState = RequestState.SUCCESS;

		this.checkContestPredictions();
	};

	init() {
		this._contestsStore.fetchContests().then(this.onSuccess).catch(this.onError);

		this._fetchPredictionDisposer = reaction(
			() => this.contestId,
			(contestId) => {
				if (!contestId) return;

				// Scroll to top of page on contest change
				window.scrollTo(0, 0);

				this._predictionsStore.clearStore();
				this._predictionRequestState = RequestState.PENDING;

				Promise.all([
					this._predictionsStore.fetchPredictions(contestId),
					this._gameBarStore.fetchGameBar(contestId),
				])
					.then(this.onFetchPredictionsSuccess)
					.catch(this.onFetchPredictionsError);

				// set banner contest first question id
				const question = first(this._contestsStore.getContestById(contestId)?.questions);
				this._bannerStore.setQuestionId(question?.id);
			},
			{fireImmediately: true}
		);
	}

	dispose() {
		this._fetchPredictionDisposer?.();
		this._gameBarStore.clearStore();
		this._predictionsStore.clearStore();
		this._modalsStore.hideModal();
	}
}
