import React, {FC, PropsWithChildren} from "react";
import styled from "@emotion/styled";
import {IconButton, Modal} from "@mui/material";
import {ReactComponent as CloseIcon} from "assets/img/icons/close.svg";

const Container = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	outline: none;
	background: ${({theme}) => theme.palette.modal.bg};
	border-radius: 8px;
	padding: 60px 20px 24px;
	left: 24px;
	right: 24px;
	transform: translateY(-50%);
	top: 50%;

	@media (min-width: 769px) {
		max-width: 600px;
		padding: 60px 24px 24px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	top: 12px;
	right: 12px;
`;

export const ModalTitle = styled.h2`
	color: ${({theme}) => theme.palette.modal.color};
	text-align: center;
	font-family: var(--fontFamilySecond);
	font-size: 20px;
	font-weight: 400;
	line-height: 100%;
	text-transform: uppercase;

	@media (min-width: 769px) {
		font-size: 24px;
	}
`;

export const ModalDesc = styled.h3`
	color: ${({theme}) => theme.palette.modal.color};
	text-align: center;
	font-family: var(--fontFamilyBase);
	font-size: 14px;
	font-weight: 400;
	line-height: 150%;

	b {
		font-weight: 600;
	}

	@media (min-width: 769px) {
		font-size: 16px;
	}
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 360px;
	width: 100%;

	&.full-width {
		max-width: none;
	}
`;

interface IModalBase {
	isOpen: boolean;
	closeHandler?: () => void;
	className?: string;
}

export const ModalBase: FC<PropsWithChildren<IModalBase>> = ({
	children,
	closeHandler,
	isOpen,
	className,
}) => {
	return (
		<Modal open={isOpen} onClose={closeHandler}>
			<Container className={className}>
				{closeHandler && (
					<CloseBlock>
						<IconButton onClick={closeHandler}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>
				)}

				{children}
			</Container>
		</Modal>
	);
};
