import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {useLocation} from "react-router-dom";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {type IAuthController} from "views/controllers/auth/auth.controller";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {AUTH_MENU, GUEST_MENU, IMenuItem} from "data/constants/menu";
import {ShareType, SocialNetwork} from "data/enums";
import {share} from "data/utils";

interface IProps {
	location: ReturnType<typeof useLocation>;
}

export interface IHeaderController extends ViewController<IProps> {
	i18n: ILocalizationStore;

	get isAuthorized(): boolean;
	get isOpenMenu(): boolean;
	get isShowTutorialButton(): boolean;
	get menu(): IMenuItem[];

	shareViaFB: () => void;
	shareViaTW: () => void;
	shareViaMobile: () => void;
	toggleMenu: () => void;
	openTutorial: () => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable private _location?: ReturnType<typeof useLocation>;

	get pathname() {
		return this._location?.pathname;
	}

	get isAuthorized() {
		return this._authController.isAuthorized;
	}

	get isOpenMenu() {
		return this._menuStore.isOpen;
	}

	get isShowTutorialButton() {
		return this.pathname === "/predictor";
	}

	get menu() {
		return this.isAuthorized ? AUTH_MENU : GUEST_MENU;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.MenuStore) public _menuStore: IMenuStore,
		@inject(Bindings.AuthController) private _authController: IAuthController,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._location = param.location;
	}

	toggleMenu = () => {
		this._menuStore.toggleMenu();
	};

	openTutorial = () => {
		this._menuStore.closeMenu();
		this._tutorialStore.setIsOpen(true);
	};

	private share(socialNetwork: SocialNetwork) {
		share({
			socialNetwork,
			lang: this.i18n.lang,
			type: ShareType.General,
			message: this.i18n.t(
				"share.default",
				`I’m playing IIHF Daily Picks - come join me and make your predictions each day for the chance to win amazing prizes!`
			),
		});
	}

	shareViaFB = () => this.share(SocialNetwork.Facebook);

	shareViaTW = () => this.share(SocialNetwork.Twitter);

	shareViaMobile = () => this.share(SocialNetwork.Mobile);
}
