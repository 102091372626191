import {makeAutoObservable, observable, reaction} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {type IContestsStore} from "data/stores/contests/contests.store";

export interface IModalLeagueInviteController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get league(): (ILeague & {startContestName?: string}) | null;

	close: () => void;
	joinLeague: () => void;
}

@injectable()
export class ModalLeagueInviteController implements IModalLeagueInviteController {
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _league: ILeague | null = null;
	@observable _navigate!: ReturnType<typeof useNavigate>;

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.LEAGUE_INVITE;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get leagueCode() {
		return this.modalContent?.leagueCode;
	}

	get league() {
		const league = this._league;

		if (!league) {
			return null;
		}

		return {
			...league,
			startContestName: this._contestsStore.getContestById(league.startContestId)?.name,
		};
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private onSuccess = (league: ILeague) => {
		this._league = league;
	};

	init() {
		this._disposer = reaction(
			() => this.leagueCode,
			(code) => {
				if (!code) return;

				this._leaguesStore
					.fetchLeagueByCode({code})
					.then(this.onSuccess)
					.catch(this.onError);
			}
		);
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	joinLeague = () => {
		this.modalContent?.callback?.();
	};

	dispose() {
		this._disposer?.();
		this._league = null;
	}
}
