import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {ILadder, IRankingApiProvider} from "data/providers/api/rankings.api.provider";
import {Bindings} from "data/constants/bindings";
import {isNull} from "lodash";

export interface IRankingStore {
	get list(): ILadder[];
	get userRank(): ILadder | null;
	get nextPage(): boolean;
	get contestId(): string;
	get isUserInTop(): boolean;
	get isUserListed(): boolean;

	setContestId(contestId: number | null): void;
	resetPageCount(): void;
	fetchLeaderboards(): Promise<void>;
	fetchMoreLeaderboards(): Promise<void>;
}

@injectable()
export class RankingStore implements IRankingStore {
	@observable private _list: ILadder[] = [];
	@observable private _userRank: ILadder | null = null;
	@observable private _nextPage: boolean = false;
	private _contestId: number | null = null;
	private _page: number = 0;
	private _limit: number = 10;

	get list() {
		return this._list;
	}

	get userRank() {
		return this._userRank;
	}

	get nextPage() {
		return this._nextPage;
	}

	get contestId() {
		return isNull(this._contestId) ? "0" : String(this._contestId);
	}

	get isUserInTop() {
		return Number(this.userRank?.rank ?? 0) < 11 && Number(this.userRank?.rank ?? 0) !== 0;
	}

	get isUserListed() {
		return Boolean(this._list.find((it) => it.userId === this._userRank?.userId)?.userId);
	}

	constructor(@inject(Bindings.RankingApiProvider) private _rankingsApi: IRankingApiProvider) {
		makeAutoObservable(this);
	}

	@action resetPageCount() {
		this._page = 0;
	}

	@action setContestId(contestId: number | null) {
		this._contestId = contestId;
	}

	async fetchLeaderboards() {
		this.clearStore();

		const {
			data: {success},
		} = await this._rankingsApi.ranking({
			limit: this._limit,
			contestId: this._contestId ? this._contestId : undefined,
		});

		runInAction(() => {
			this._list = success.ladder;
			this._userRank = success.user;
			this._nextPage = success.nextPage;
			this._page = 1;
		});
	}

	async fetchMoreLeaderboards() {
		this._page += 1;

		const {
			data: {success},
		} = await this._rankingsApi.ranking({
			page: this._page,
			limit: this._limit,
			contestId: this._contestId ? this._contestId : undefined,
		});

		runInAction(() => {
			this._list = [...this._list, ...success.ladder];
			this._nextPage = success.nextPage;
		});
	}

	clearStore = () => {
		this._list = [];
		this._userRank = null;
		this._nextPage = false;
		this._page = 0;
	};
}
