import React from "react";
import {observer} from "mobx-react";
import {Button, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useTimer} from "data/hooks/useTimer";
import {
	ModalBase,
	ModalContent,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {IModalNextContestController} from "./modal_next_contest.controller";
import {ReactComponent as SuccessIcon} from "assets/img/icons/success.svg";

const Icon = styled.div`
	margin-bottom: 16px;

	svg {
		width: 100%;
		height: auto;
	}
`;

const Title = styled(ModalTitle)`
	color: ${({theme}) => theme.palette.modal.color};
	margin-bottom: 8px;
`;

const Desc = styled(ModalDesc)`
	margin-bottom: 16px;
`;

export const ModalNextContest: React.FC = observer(() => {
	const {isOpen, i18n, close, contestName, nextContestName, lockDate, submit} =
		useViewController<IModalNextContestController>(Bindings.ModalNextContestController);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close}>
			<ModalContent className="full-width">
				<Icon>
					<SuccessIcon />
				</Icon>

				<Title>
					{i18n.t(
						"next_contest.save_modal.header",
						"Great work! Your picks are in for Game Day {{X}}",
						{X: contestName}
					)}
				</Title>

				<Desc
					/*Disabled due html come from our Loco and must be safety*/
					/*eslint-disable-next-line*/
					dangerouslySetInnerHTML={{
						__html: i18n.t(
							"next_contest.save_modal.body",
							"You have <b>{{X}}</b> left to edit your picks. Check back every day to see your results and play again.",
							{
								X: useTimer(lockDate),
							}
						),
					}}
				/>

				<Stack direction="row" gap={1.5} sx={{width: "100%"}}>
					<Button fullWidth variant="contained" onClick={submit}>
						{i18n.t("next_contest.save_modal.button", "PICK {{X}}", {
							X: nextContestName,
						})}
					</Button>
				</Stack>
			</ModalContent>
		</ModalBase>
	);
});
