/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {PaletteColorOptions, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface CustomPalette {
		apple: PaletteColorOptions;
		page: {
			bg: string;
			divider: string;
			primaryColor: string;
			secondaryColor: string;
		};
		banner: {
			openBg: string;
			openColor: string;
			lockedBg: string;
			lockedColor: string;
			completeBg: string;
			completeColor: string;
		};
		gameBar: {
			bg: string;
			valueColor: string;
		};
		roundSelector: {
			bg: string;
			color: string;
		};
		landing: {
			tileBg: string;
			tileColor: string;
			tileBorderColor: string;
		};
		footer: {
			color: string;
		};
		legend: {
			bg: string;
			color: string;
		};
		slider: {
			dot: string;
			activeDot: string;
		};
		header: {
			color: string;
			bg: string;
		};
		modal: {
			color: string;
			bg: string;
			secondColor: string;
		};
		question: {
			borderColor: string;
			bg: string;
			color: string;
			headColor: string;
			headBg: string;
			imageBorder: string;
			optionBg: string;
			activeOptionBorder: string;
			correctOption: string;
			unCorrectOption: string;
			rightAnswerBg: string;
		};
		questionSlider: {
			bg: string;
			activeBg: string;
			activeBoosterBg: string;
			markColor: string;
			activeMarkColor: string;
			answerBorder: string;
			answerBg: string;
			answerActiveBorder: string;
			answerActiveBg: string;
		};
		questionSelect: {
			color: string;
			bg: string;
			arrowBg: string;
			openArrowBg: string;
			answeredBg: string;
			answeredColor: string;
			border: string;
			openColor: string;
		};
		notFound: {
			bg: string;
			color: string;
		};
		leaguePage: {
			bg: string;
			color: string;
			formBg: string;
			border: string;
		};
		helpPage: {
			bg: string;
			color: string;
		};
		leagueNav: {
			bg: string;
			color: string;
			border: string;
			indicator: string;
		};
		leagueWidget: {
			bg: string;
			color: string;
			border: string;
		};
		leagueCard: {
			bg: string;
			publicBg: string;
			publicColor: string;
			privateBg: string;
			privateColor: string;
		};
		snackbar: {
			bg: string;
			color: string;
		};
		leagueManage: {
			tableHeadBg: string;
			tableHeadColor: string;
			tableBodyMeBg: string;
			tableBodyMeColor: string;
			loadMoreColor: string;
		};
		tutorial: {
			color: string;
			bg: string;
			paginationBg: string;
			activePaginationBg: string;
		};
		tutorialBanner: {
			bg: string;
			color: string;
		};
		boosterAnimation: {
			primary: string;
			secondary: string;
			button: string;
		};
		summaryView: {
			border: string;
			correctBorder: string;
			wrongBorder: string;
			primaryColor: string;
			secondaryColor: string;
			boosterColor: string;
			answerBg: string;
			correctBg: string;
			wrongBg: string;
			correctColor: string;
			wrongColor: string;
		};
		leaderboard: {
			bg: string;
			color: string;
			selectBg: string;
			border: string;
			headColor: string;
			headBg: string;
			userRowBg: string;
			userRowColor: string;
		};
	}

	interface Palette extends CustomPalette {}

	interface PaletteOptions extends CustomPalette {}
}

export const paletteTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#000F9F",
		},
		secondary: {
			main: "#e1ff67",
		},
		apple: {
			main: "#FFF",
		},
		error: {
			main: "#ff0000",
		},
		warning: {
			main: "#fff100",
		},
		info: {
			main: "#00d4dc",
		},
		success: {
			main: "#60ff00",
		},
		page: {
			bg: "#000F9F",
			divider: "#FF3621",
			primaryColor: "#121212",
			secondaryColor: "#ffffff",
		},
		banner: {
			openBg: "#000000",
			openColor: "#ffffff",
			lockedBg: "#AE2E28",
			lockedColor: "#ffffff",
			completeBg: "#008805",
			completeColor: "#ffffff",
		},
		gameBar: {
			bg: "#ffffff",
			valueColor: "#000F9F",
		},
		roundSelector: {
			bg: "#ffffff",
			color: "#000000",
		},
		landing: {
			tileBg: "#ffffff",
			tileColor: "#000000",
			tileBorderColor: "#e5e5ea",
		},
		footer: {
			color: "#000000",
		},
		legend: {
			bg: "#ffffff",
			color: "#0A0A0A",
		},
		slider: {
			dot: "#DBDBDB",
			activeDot: "#F9423A",
		},
		header: {
			color: "#000000",
			bg: "#ffffff",
		},
		modal: {
			color: "#000000",
			bg: "#ffffff",
			secondColor: "#000f9f",
		},
		questionSlider: {
			bg: "#F6F6F6",
			activeBg: "#F9423A",
			activeBoosterBg: "#000F9F",
			markColor: "#6D6D6D",
			activeMarkColor: "#F6F6F6",
			answerBorder: "#DBDBDB",
			answerBg: "#ffffff",
			answerActiveBorder: "#000F9F",
			answerActiveBg: "#E0E2F3",
		},
		questionSelect: {
			color: "#707070",
			bg: "#000F9F",
			arrowBg: "#000000",
			border: "#DBDBDB",
			openArrowBg: "#ffffff",
			openColor: "#ffffff",
			answeredBg: "#E0E2F3",
			answeredColor: "#000000",
		},
		question: {
			borderColor: "#F6F6F6",
			bg: "#ffffff",
			color: "#000000",
			headColor: "#ffffff",
			headBg: "#000F9F",
			imageBorder: "#DBDBDB",
			optionBg: "#F6F6F6",
			activeOptionBorder: "#000F9F",
			correctOption: "#008805",
			unCorrectOption: "#E40000",
			rightAnswerBg: "#C5E0C6",
		},
		notFound: {
			bg: "#F6F6F6",
			color: "#000000",
		},
		leaguePage: {
			bg: "#F6F6F6",
			color: "#000000",
			formBg: "#ffffff",
			border: "#DBDBDB",
		},
		helpPage: {
			bg: "#F6F6F6",
			color: "#000000",
		},
		leagueNav: {
			bg: "#ffffff",
			color: "#000F9F",
			border: "#DBDBDB",
			indicator: "#F9423A",
		},
		leagueWidget: {
			bg: "#ffffff",
			color: "#000000",
			border: "#DBDBDB",
		},
		leagueCard: {
			bg: "#ffffff",
			publicBg: "#F9423A",
			publicColor: "#ffffff",
			privateBg: "#000000",
			privateColor: "#ffffff",
		},
		snackbar: {
			bg: "#008805",
			color: "#ffffff",
		},
		leagueManage: {
			tableHeadBg: "#000a6f",
			tableHeadColor: "#ffffff",
			tableBodyMeBg: "#000f9f",
			tableBodyMeColor: "#ffffff",
			loadMoreColor: "#000000",
		},
		tutorial: {
			color: "#000000",
			bg: "#ffffff",
			paginationBg: "#DBDBDB",
			activePaginationBg: "#F9423A",
		},
		tutorialBanner: {
			bg: "#ffffff",
			color: "#000F9F",
		},
		boosterAnimation: {
			primary: "#FF3621",
			secondary: "#ffffff",
			button: "#F9423A",
		},
		summaryView: {
			border: "#000F9F",
			correctBorder: "#008805",
			wrongBorder: "#F9423A",
			primaryColor: "#000000",
			secondaryColor: "#000F9F",
			boosterColor: "#ffffff",
			answerBg: "#F6F6F6",
			correctBg: "#00880519",
			wrongBg: "#FBD9D9",
			correctColor: "#008805",
			wrongColor: "#E40000",
		},
		leaderboard: {
			bg: "#ffffff",
			color: "#000000",
			selectBg: "#ffffff",
			border: "#DBDBDB",
			headColor: "#ffffff",
			headBg: "#000a6f",
			userRowBg: "#000F9F",
			userRowColor: "#ffffff",
		},
	},
};

type PaletteInterface = (typeof paletteTheme)["palette"];

declare module "@emotion/react" {
	export interface Theme {
		palette: NonNullable<PaletteInterface>;
	}
}
