import React from "react";
import {observer} from "mobx-react";
import {Button, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useTimer} from "data/hooks/useTimer";
import {
	ModalBase,
	ModalContent,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {IModalLeaguesController} from "./modal_leagues.controller";
import {ReactComponent as SuccessIcon} from "assets/img/icons/success.svg";

const Icon = styled.div`
	margin-bottom: 16px;

	svg {
		width: 100%;
		height: auto;
	}
`;

const Title = styled(ModalTitle)`
	color: ${({theme}) => theme.palette.modal.color};
	margin-bottom: 8px;
`;

const Desc = styled(ModalDesc)`
	margin-bottom: 16px;
`;

export const ModalLeagues: React.FC = observer(() => {
	const {isOpen, i18n, close, contest, lockDate} = useViewController<IModalLeaguesController>(
		Bindings.ModalLeaguesController
	);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close}>
			<ModalContent className="full-width">
				<Icon>
					<SuccessIcon />
				</Icon>

				<Title>
					{i18n.t(
						"league.save_modal.header",
						"Great work! Your picks are in for Game Day {{X}}",
						{X: contest?.name}
					)}
				</Title>

				<Desc
					/*Disabled due html come from our Loco and must be safety*/
					/*eslint-disable-next-line*/
					dangerouslySetInnerHTML={{
						__html: i18n.t(
							"league.save_modal.body",
							"You have <b>{{X}}</b> left to edit your picks. Check back every day to see your results and play again.",
							{
								X: useTimer(lockDate),
							}
						),
					}}
				/>

				<Stack gap={1.5} sx={{flexDirection: {xs: "column", md: "row"}, width: "100%"}}>
					<Button
						fullWidth
						variant="contained"
						onClick={close}
						component={NavLink}
						to={"/leagues/create"}>
						{i18n.t("league.save_modal.create_button", "create a league")}
					</Button>

					<Button
						fullWidth
						variant="contained"
						color="secondary"
						onClick={close}
						component={NavLink}
						to={"/leagues/join"}>
						{i18n.t("league.save_modal.join_button", "join a league")}
					</Button>
				</Stack>
			</ModalContent>
		</ModalBase>
	);
});
