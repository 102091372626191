export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	REGISTER,
	LEAGUE_INVITE,
	NEXT_CONTEST,
	LEAGUES,
	BOOSTER,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum QuestionStatus {
	Locked = "locked",
	Complete = "completed",
	Open = "open",
}

export enum QuestionType {
	Multiple_Choice = "multiple_choice",
	Drop_Down = "drop_down",
	Numerical_Slider = "numerical_slider",
}

export enum ContestStatus {
	Open = "open",
	Live = "live",
	Complete = "complete",
}

export enum OptionImageType {
	PlayerHeadshot = "player_headshot",
	FlagsAndIcons = "flags_and_icons",
	Slider = "slider",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
	FAN = "fan",
	OVERALL_PLAYOFF = "overall_playoff",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum OptionsStructureType {
	Default = "default",
	Grid = "grid",
}

export enum RankStatus {
	UP,
	DOWN,
	SAME,
}
