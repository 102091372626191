import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface ILoginPayload {
	email: string;
	password: string;
}

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface ISsoLoginPayload {
	iihfUserId: string;
	verify: string;
}

export interface ISsoRegisterPayload extends ISsoLoginPayload {
	displayName: string;
	isNotificationsEnabled: boolean;
	isNotificationsFromPartnersEnabled: boolean;
	favouriteTeam: string;
}

export interface IAuthApiProvider {
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
	ssoLogin: (params: ISsoLoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	ssoRegister: (params: ISsoRegisterPayload) => Promise<AxiosResponse<TLoginResponse>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("auth/login", params);

	logout = () => this._http.post("auth/logout");

	ssoLogin = (params: ISsoLoginPayload) => this._http.post<TLoginResponse>("iihf/login", params);

	ssoRegister = (params: ISsoRegisterPayload) =>
		this._http.post<TLoginResponse>("iihf/register", params);
}
