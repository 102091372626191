import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {copyToClipboard, share} from "data/utils";
import {ShareType, SocialNetwork} from "data/enums";
import {ORIGIN} from "data/constants";

interface IProps {
	league: ILeague | null;
}

export interface ILeagueInviteFormController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isCodeCopied(): boolean;
	get isMobileShareVisible(): boolean;

	copyCode: () => void;
	copyLink: () => void;
	shareViaFB: () => void;
	shareViaTW: () => void;
	shareViaMobile: () => void;
}

@injectable()
export class LeagueInviteFormController implements ILeagueInviteFormController {
	@observable private _isNarrowScreen: boolean = false;
	@observable private _league: ILeague | null = null;
	@observable private _isCodeCopied = false;
	@observable private _copyCodeTextTimeout?: ReturnType<typeof setTimeout>;

	get isCodeCopied() {
		return this._isCodeCopied;
	}

	get isMobileShareVisible() {
		return "share" in navigator && this._isNarrowScreen;
	}

	private get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 960px)");
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	@action private onCopyCodeState = () => {
		this._isCodeCopied = false;
	};

	@action private onCopyCodeCallback = (isSuccess: boolean) => {
		this._isCodeCopied = isSuccess;

		if (isSuccess) {
			this._copyCodeTextTimeout = setTimeout(this.onCopyCodeState, 5000);
		}
	};

	copyCode = () => {
		const code = this._league?.code ?? "";

		copyToClipboard(code).then(this.onCopyCodeCallback).catch(this.onCopyCodeCallback);
	};

	copyLink = () => {
		const code = this._league?.code ?? "";
		const link = `${ORIGIN}leagues/join/${code}`;

		copyToClipboard(link).then(this.onCopyCodeCallback).catch(this.onCopyCodeCallback);
	};

	private share(socialNetwork: SocialNetwork) {
		const leagueId = this._league?.id;

		if (!leagueId) return;

		share({
			leagueId,
			socialNetwork,
			lang: this.i18n.lang,
			type: ShareType.League,
			message: this.i18n.t(
				"share.league",
				`Join my IIHF Daily Picks league by using the code: {{X}}. Make your predictions for each game and compete for amazing prizes!`,
				{X: this._league?.code}
			),
		});
	}

	shareViaFB = () => this.share(SocialNetwork.Facebook);

	shareViaTW = () => this.share(SocialNetwork.Twitter);

	shareViaMobile = () => this.share(SocialNetwork.Mobile);

	@action private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	@action init(param: IProps) {
		this._league = param.league;

		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);
	}

	@action onChange(param: IProps) {
		this._league = param.league;
	}

	dispose() {
		this.narrowScreenWatcher.removeEventListener("change", this.updateNarrowScreenFlag);

		if (this._copyCodeTextTimeout) clearTimeout(this._copyCodeTextTimeout);
	}
}
